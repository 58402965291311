import React, { useState } from 'react';
import StockPage from './StockPage';
import AddOrEditForm from './AddOrEditForm';
import './Main.css';

function Main() {
  const [view, setView] = useState('stock');


  return (
    <div className="container-fluid">
      {view === 'stock' && <StockPage />}
      {view === 'create' && (
        <AddOrEditForm
          product={null} // No product passed for new creation
          onSave={() => setView('stock')}
          onCancel={() => setView('stock')}
          formTitle="Create New"
        />
      )}
    </div>
  );
}

export default Main;