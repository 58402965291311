// src/components/product_sql/BrandSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function BrandSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'brandid', label: 'ID' },
    { key: 'brandname', label: 'Brand' },
  ];

  const fields = [
    { key: 'brandname', label: 'ยี่ห้อ', required: true },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/brandsql/"
      columns={columns}
      itemName="ยี่ห้อ"
      dataKey="brandname"
      idKey="brandid"
      pageName="brand"
      storageKey="brandSqlTableSortState"
      cancelKey="brandcancel"
      fields={fields}
    />
  );
}

export default BrandSqlTable;