//src/components/GenericSqlTable.js
import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';

function GenericSqlTable({ apiUrl, title, onSelect, filterKey, idKey, nameKey, columns, searchKey }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');
  
  // Create a ref for the search input
  const searchInputRef = useRef(null);

  useEffect(() => {
    axiosInstance.get(apiUrl)
      .then(response => {
        const activeItems = response.data.filter(item => !item[filterKey]);
        setData(activeItems);
        setFilteredData(activeItems);
      })
      .catch(error => {
        console.error(`There was an error fetching the data from ${apiUrl}!`, error);
      });
  }, [apiUrl, filterKey]);

  // Focus the search input when the component mounts
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilter(value);

    const filteredItems = data.filter(item =>
      item[searchKey].toLowerCase().includes(value)
    );
    setFilteredData(filteredItems);
  };

  const handleRowClick = (item) => {
    onSelect(item);  // Pass the selected name back to the parent component
  };

  return (
    <div className="container mt-3">
      <h2>{title}</h2> 
      <form className="mb-3">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder={`Search by ${nameKey}`}
              value={filter}
              onChange={handleFilterChange}
              ref={searchInputRef}  // Attach the ref to the input
            />
          </div>
        </div>
      </form>

      <table className="table table-striped">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr
                key={item[idKey]}
                onClick={() => handleRowClick(item)}
                style={{ cursor: 'pointer' }}
              >
                {columns.map((column) => (
                  <td key={column.key}>{item[column.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
      </table>
    </div>
  );
}

export default GenericSqlTable;