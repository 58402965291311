// src/components/product_sql/SizeSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function SizeSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'idprodsize', label: 'ID' },
    { key: 'sizename', label: 'ไซส์' },
  ];

  const fields = [
    { key: 'sizename', label: 'ไซส์', required: true },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/sizesql/"
      columns={columns}
      itemName="ไซส์"
      dataKey="sizename"
      idKey="idprodsize"
      pageName="size"
      storageKey="sizeSqlTableSortState"
      cancelKey="sizecancel"
      fields={fields} // Adding fields prop
    />
  );
}

export default SizeSqlTable;