// src/components/ThingtoServAddform.js
import React from 'react';
import axiosInstance from '../../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';

import ServThingAddModal from './ServThingAddModal';
//import { useDispatch } from 'react-redux';


function ServiceThingAddF({ thing, serviceId, onSave, onCancel, show }) {
 
const handleAddThingConfirm = async (formData) => {
  // Handle null values for servprodcolor
    const formDataToSubmit = {
      ...formData,
      idservicesell: thing ? thing.idservicesell : null,
      servprodcolor: formData.servprodcolor ? formData.servprodcolor : null,
      servprodbrand: formData.servprodbrand ? formData.servprodbrand : null,
      servprodmodel: formData.servprodmodel ? formData.servprodmodel : null,
      servprodsize: formData.servprodsize ? formData.servprodsize : null,
      servsellstaff: formData.servsellstaff ? formData.servsellstaff : null,
      servfinishdateplan: formData.servfinishdateplan ? formData.servfinishdateplan : null,
    };
    console.log('Data being sent:', formDataToSubmit);
    try {
      let response;
      if (thing && thing.idservicesell) {
        // Update existing entry
        response = await axiosInstance.put(`/update-thingtoserv/${thing.idservicesell}/`, formDataToSubmit);
      } else {
        // Create new entry
        response = await axiosInstance.post(`/add-thingtoserv/`, formDataToSubmit);
      }
      console.log('Response data:', response.data); // Log the response data

      if (response.data && response.data.idservicesell) {
        onSave(response.data); // Make sure to pass the updated `thing` data with `idservicesell`
      } else {
        console.error('Invalid response data:', response.data);
      }
     onCancel();
      
    } catch (error) {
      console.error('Error saving data:', error);
  }
};

  return (
    <ServThingAddModal
    thing={thing}
    show={show}
    handleConfirm={handleAddThingConfirm}
    serviceId={serviceId}
    onCancel={onCancel}
  />
  );
}

export default ServiceThingAddF;