//src/components/StockPage.js
import React, { useEffect, useState, useCallback } from 'react';
import axiosInstance from '../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';
import FilterForm from './FilterForm';
import StockTable from './StockTable';
import AddOrEditForm from './AddOrEditForm';
import DoWithLineModal from '../utils/DoWithLineModal'; // Adjust the path as needed



function StockPage({ handleSidebarToggle }) {
  const [stockData, setStockData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    categoryname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    barcode: '',
    suppname: '',
  });
  const [seeAll, setSeeAll] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formMode, setFormMode] = useState(''); // 'edit' or 'create'
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false); // New state for delete confirmation
  
 const fetchStockData = useCallback(() => {
  axiosInstance.get(`/stview/`)
    .then(response => {
      setStockData(response.data);
    })
    .catch(error => {
      console.error('There was an error fetching the stock data!', error);
    });
}, []);

  // useEffect to run only once when the component mounts
  useEffect(() => {
    fetchStockData();  // Fetch data when the component mounts
  }, [fetchStockData]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleClearFilters = () => {
    setFilters({
      name: '',
      categoryname: '',
      typename: '',
      brandname: '',
      modelname: '',
      sizename: '',
      colorname: '',
      barcode: '',
      suppname: '', 
    });
  };

  const handleSeeAllChange = () => {
    setSeeAll(!seeAll);
  };

  const handleEditClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleEdit = () => {
    setFormMode('edit');
    setShowModal(false);
    setIsFormVisible(true);
    setSelectedProduct(selectedProduct);
  };

  const handleCreateNewFromOld = () => {
    setFormMode('create');
    setShowModal(false);
    setIsFormVisible(true);
    setSelectedProduct(selectedProduct);
  };

  const handleCreateNew = () => {
    setFormMode('create');
    setShowModal(false);
    setIsFormVisible(true);
    setSelectedProduct(null);
  };

  const handleSave = (savedProduct) => {
    if (formMode === 'edit') {
      setStockData(stockData.map(product => product.prodid === savedProduct.prodid ? savedProduct : product));
    } else if (formMode === 'create') {
      setStockData([...stockData, savedProduct]); // Append the new product
    }
    setIsFormVisible(false);
    fetchStockData(); // Refresh the stock table
  };

  const handleCancel = () => {
    setIsFormVisible(false);
    setShowModal(false);
  };

  const handleDelete = () => {
    setIsDeleteConfirmation(true);  // Switch to delete confirmation mode
  };

  const confirmDelete = () => {
    if (selectedProduct) {
      axiosInstance.patch(`/update-prodcancel/${selectedProduct.prodid}/`, { prodcancel: true })
        .then(response => {
          setStockData(stockData.map(product => product.prodid === selectedProduct.prodid ? { ...product, prodcancel: true } : product));
          setShowModal(false);
          setIsDeleteConfirmation(false); // Reset delete confirmation mode
          fetchStockData(); // Refresh the stock table
        })
        .catch(error => {
          console.error('There was an error deleting the product!', error);
        });
    }
  };


  const filteredStockData = stockData.filter(item => {
    const matchesFilters = (
      (item.name || '').toLowerCase().includes(filters.name.toLowerCase()) &&
      (item.categoryname || '').toLowerCase().includes(filters.categoryname.toLowerCase()) &&
      (item.typename || '').toLowerCase().includes(filters.typename.toLowerCase()) &&
      (item.brandname || '').toLowerCase().includes(filters.brandname.toLowerCase()) &&
      (item.modelname || '').toLowerCase().includes(filters.modelname.toLowerCase()) &&
      (item.sizename || '').toLowerCase().includes(filters.sizename.toLowerCase()) &&
      (item.colorname || '').toLowerCase().includes(filters.colorname.toLowerCase()) &&
      (item.barcode || '').toLowerCase().includes(filters.barcode.toLowerCase()) &&
      (item.suppname || '').toLowerCase().includes(filters.suppname.toLowerCase())
    );
    const matchesSeeAll = seeAll ? !item.prodcancel : (item.st > 0 && !item.prodcancel);
    return matchesFilters && matchesSeeAll;
  });

  return (
    <div className="container pt-1">
      {!isFormVisible && (
        <>
        <div className="filter-form-wrapper"style={{ maxWidth: '800px', width: '100%' }}>
          <FilterForm 
            filters={filters} 
            handleFilterChange={handleFilterChange} 
            handleClearFilters={handleClearFilters} 
            seeAll={seeAll} 
            handleSeeAllChange={handleSeeAllChange} 
            handleSidebarToggle={handleSidebarToggle}
          />
        </div>
        <StockTable 
        data={filteredStockData} 
        onEdit={handleEditClick} 
        seeAll={seeAll}
        selectable={true}
        onAddNew={handleCreateNew}
        />
        </>
      )}
      {isFormVisible && (
        <AddOrEditForm
          product={selectedProduct}
          onSave={handleSave}
          onCancel={handleCancel}
          formTitle={formMode === 'edit' ? 'แก้ไข' : 'สร้างใหม่'}
        />
      )}
        <DoWithLineModal
          show={showModal}
          onHide={() => setShowModal(false)}
          isDeleteConfirmation={isDeleteConfirmation}
          itemName={selectedProduct ? selectedProduct.name : ''}
          onEdit={handleEdit}
          onCreateNew={handleCreateNewFromOld}
          onDelete={handleDelete}
          onConfirmDelete={confirmDelete}
          onCancelDeleteConfirmation={() => setIsDeleteConfirmation(false)}
        />
    </div>
  );
}

export default StockPage;
