import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/apiClient';
import CustomTable from '../../../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';
import DoWithLineModal from '../../../utils/DoWithLineModal';

function SellJobViewTable({ sellServId, showAll, dataVersion }) {
  const [sellJobs, setSellJobs] = useState([]);
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'sellJob'));
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedSellJob, setSelectedSellJob] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  // Fetch sell jobs based on sellServId and apply the showAll filter
  const fetchSellJobs = async (sellServId) => {
    try {
      const response = await axiosInstance.get('/Selljobv/', {
        params: {
          servsellid: sellServId,
        },
      });

      // If showAll is true, show all jobs. Otherwise, filter out canceled jobs.
      const filteredJobs = showAll 
        ? response.data 
        : response.data.filter(job => !job.servjobcancle);

      // Process the data to ensure numeric fields are valid numbers
      const processedJobs = filteredJobs.map((job) => ({
        ...job,
        servjobprice: job.servjobprice != null ? job.servjobprice : 0,
        servjobdisc: job.servjobdisc != null ? job.servjobdisc : 0,
        servjobqty: job.servjobqty != null ? job.servjobqty : 0,
      }));

      setSellJobs(processedJobs);
    } catch (error) {
      console.error('Error fetching sell jobs:', error);
    }
  };

  useEffect(() => {
    if (sellServId) {
      fetchSellJobs(sellServId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellServId, showAll, dataVersion]);

  // Calculate the total sum for the sumServPrice column
  const calculateTotalSum = () => {
    return sellJobs.reduce((acc, job) => {
      const price = Number(job.servjobprice) || 0;
      const discount = Number(job.servjobdisc) || 0;
      const qty = Number(job.servjobqty) || 0;
      const total = (price - discount) * qty;
      return acc + total;
    }, 0);
  };

  const totalSum = calculateTotalSum();

    const footerData = {
      servjobqty: {
        content: 'รวม', // 'Total' in Thai
        className: 'footer-total-label', // Custom class name
      },
      sumServPrice: {
        content: totalSum.toLocaleString(),
        className: 'footer-sum-price', // Custom class name
      },
    };
  

  // Define the columns for the CustomTable
  const columns = [
    {
      key: 'servicename',
      label: 'ชื่อบริการ',
      sortable: true,
      render: (row) => row.servicename,
    },
    {
      key: 'servjobcomment',
      label: 'ความคิดเห็น',
      sortable: true,
      render: (row) => row.servjobcomment || '-',
    },
    {
      key: 'staffname',
      label: 'ชื่อช่าง',
      sortable: true,
      render: (row) => row.staffname,
    },
    {
      key: 'servjobprice',
      label: 'ราคา',
      sortable: true,
      render: (row) => row.servjobprice ? row.servjobprice.toLocaleString() : '0',
      className: 'text-end',
    },
    {
      key: 'servjobdisc',
      label: 'ส่วนลด',
      sortable: true,
      render: (row) => row.servjobdisc ? row.servjobdisc.toLocaleString() : '0',
      className: 'text-end',
    },
    {
      key: 'servjobqty',
      label: 'จำนวน',
      sortable: true,
      render: (row) => row.servjobqty ? row.servjobqty.toLocaleString() : '0',
      className: 'text-center',
    },
    {
      key: 'sumServPrice',
      label: 'รวม',
      sortable: false,
      render: (row) => {
        const price = Number(row.servjobprice) || 0;
        const discount = Number(row.servjobdisc) || 0;
        const qty = Number(row.servjobqty) || 0;
        const total = (price - discount) * qty;
        return total.toLocaleString();
      },
      className: 'text-end',
    },
    {
      key: 'servjobcancle',
      label: 'ยกเลิก',
      sortable: true,
      render: (row) => (row.servjobcancle ? 'ใช่' : 'ไม่ใช่'),
    },
  ];

  // Initialize selected columns in Redux store if not already set
  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      // Set selected columns to show all columns by default if there are no selected columns in Redux
      const allColumnKeys = columns.map((col) => col.key);
      dispatch(setColumns({ page: 'sellJob', columns: allColumnKeys }));
    }
  }, [dispatch, selectedColumns, columns]);

  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'sellJob', columns: newSelectedColumns }));
  };

  // Handle Edit click
  const handleEditClick = (sellJob) => {
    setSelectedSellJob(sellJob);
    setShowModal(true);
    setIsDeleteConfirmation(false); // Reset delete confirmation state
  };

  // Handle Delete action
  const onDelete = () => {
    setIsDeleteConfirmation(true);
  };

  // Confirm Delete action
  const onConfirmDelete = async () => {
    if (selectedSellJob) {
      try {
        // Update servjobcancle to true for the selected job
        await axiosInstance.patch(`/Selljobsql/${selectedSellJob.idservjob}/`, {
          servjobcancle: true,
        });

        // Refresh the sell jobs data
        fetchSellJobs(sellServId);

        setShowModal(false);
        setIsDeleteConfirmation(false);
        alert('Sell job canceled successfully.');
      } catch (error) {
        console.error('Error canceling sell job:', error);
        alert('Error canceling sell job.');
      }
    }
  };

  const onCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  return (
    <div className="container">
      {sellJobs.length > 0 ? (
        <>
        <h5>งานที่ทำ</h5>
          <CustomTable
            data={sellJobs}
            columns={columns}
            selectedColumns={selectedColumns}
            onColumnsChange={handleColumnsChange}
            rowsPerPage={10}
            rowIdKey="idservjob" // Use the correct key for job ID
            storageKey="sellJobTableSortState"
            showEditColumn={true} // Enable the Edit column
            onEdit={handleEditClick} // Pass the edit handler
            selectable={false}
            footerData={footerData} // Pass the footer data
            tableClassName="sell-job-table" // Add this line
          />
          {/* DoWithLineModal for handling edit/delete actions */}
          <DoWithLineModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setIsDeleteConfirmation(false);
            }}
            itemName={`${selectedSellJob ? selectedSellJob.servicename : ''} ${selectedSellJob ? selectedSellJob.servjobname : ''}`}
            onEdit={() => {
              // Implement edit functionality if needed
            }}
            onDelete={onDelete}
            onConfirmDelete={onConfirmDelete}
            onCancelDeleteConfirmation={onCancelDeleteConfirmation}
            isDeleteConfirmation={isDeleteConfirmation}
            showCreateNew={false}
          />
        </>
      ) : (
        <p>ของมาเซอร์วิซนี้ ไม่มีงานที่ทำ</p>
      )}
    </div>
  );
}

export default SellJobViewTable;