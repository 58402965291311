import React from 'react';
import SqlTable from './SqlTable';

function CatSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'catid', label: 'ID' },
    { key: 'categoryname', label: 'ประเภท' },
  ];

  const fields = [
    { key: 'categoryname', label: 'ประเภท', required: true },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/catsql/"
      columns={columns}
      itemName="ประเภท"
      dataKey="categoryname"
      idKey="catid"
      pageName="category"
      storageKey="catSqlTableSortState"
      cancelKey="catcancel"
      fields={fields}
    />
  );
}

export default CatSqlTable;