// src/redux/slices/expandedRowSlice.js
import { createSlice } from '@reduxjs/toolkit';

const expandedRowSlice = createSlice({
  name: 'expandedRow',
  initialState: {
    expandedServiceId: null,
    expandedChildRowId: null,
  },
  reducers: {
    setExpandedRow: (state, action) => {
      state.expandedServiceId = action.payload.expandedServiceId;
      state.expandedChildRowId = action.payload.expandedChildRowId;
    },
    clearExpandedRow: (state) => {
      state.expandedServiceId = null;
      state.expandedChildRowId = null;
    },
  },
});

export const { setExpandedRow, clearExpandedRow } = expandedRowSlice.actions;
export const selectExpandedRow = (state) => state.expandedRow;
export default expandedRowSlice.reducer;