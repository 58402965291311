// src/hooks/useSortable.js

import { useState, useMemo, useEffect } from 'react';

/**
 * useSortable - A custom hook to manage sorting logic for tables with persistent state.
 *
 * @param {Array} data - The data array to be sorted.
 * @param {Array} columns - The column definitions array.
 * @param {string} storageKey - A unique key to store sort state in localStorage.
 * @returns {Object} - An object containing sortedData, handleSort, sortColumn, and sortDirection.
 */
const useSortable = (data, columns, storageKey) => {
  // Initialize sortColumn and sortDirection from localStorage or defaults
  const [sortColumn, setSortColumn] = useState(() => {
    const savedSortColumn = localStorage.getItem(`${storageKey}_sortColumn`);
    return savedSortColumn || null;
  });

  const [sortDirection, setSortDirection] = useState(() => {
    const savedSortDirection = localStorage.getItem(`${storageKey}_sortDirection`);
    return savedSortDirection || 'asc';
  });

  /**
   * handleSort - Toggles sort direction or sets a new sort column.
   *
   * @param {string} columnKey - The key of the column to sort by.
   */
  const handleSort = (columnKey) => {
    if (sortColumn === columnKey) {
      // Toggle sort direction
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      // Set new sort column and default to descending
      setSortColumn(columnKey);
      setSortDirection('desc');
    }
  };

  /**
   * Effect to save sort state to localStorage whenever it changes.
   */
  useEffect(() => {
    if (sortColumn) {
      localStorage.setItem(`${storageKey}_sortColumn`, sortColumn);
      localStorage.setItem(`${storageKey}_sortDirection`, sortDirection);
    } else {
      localStorage.removeItem(`${storageKey}_sortColumn`);
      localStorage.removeItem(`${storageKey}_sortDirection`);
    }
  }, [sortColumn, sortDirection, storageKey]);

  /**
   * sortedData - Memoized sorted data based on sortColumn and sortDirection.
   */
  const sortedData = useMemo(() => {
    if (!sortColumn) return data;

    // Find the column definition for the sortColumn
    const columnDef = columns.find((col) => col.key === sortColumn);
    if (!columnDef) return data;

    // Clone the data to avoid mutating the original array
    const sorted = [...data].sort((a, b) => {
      const aVal = a[sortColumn];
      const bVal = b[sortColumn];

      // Handle null or undefined values
      if (aVal === null || aVal === undefined) return 1;
      if (bVal === null || bVal === undefined) return -1;

      // Define which columns should be treated as dates
      const dateColumns = ['jobdate', 'jobdatefinishplan', 'realstart', 'realfinish'];
      if (dateColumns.includes(sortColumn)) {
        const aDate = new Date(aVal);
        const bDate = new Date(bVal);
        if (isNaN(aDate) || isNaN(bDate)) return 0;
        return sortDirection === 'asc' ? aDate - bDate : bDate - aDate;
      }

      // If the value is a number, compare numerically
      if (typeof aVal === 'number' && typeof bVal === 'number') {
        return sortDirection === 'asc' ? aVal - bVal : bVal - aVal;
      }

      // For strings, compare lexicographically
      return sortDirection === 'asc'
        ? aVal.toString().localeCompare(bVal.toString())
        : bVal.toString().localeCompare(aVal.toString());
    });

    return sorted;
  }, [data, sortColumn, sortDirection, columns]);

  return {
    sortedData,
    handleSort,
    sortColumn,
    sortDirection,
  };
};

export default useSortable;