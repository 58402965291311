// src/components/Pagination.js

import React, { useState, useEffect, useMemo } from 'react';
import ReactPaginate from 'react-paginate';

function PaginationComponent({ data, rowsPerPage, renderTableRows, children }) {
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [data.length]);

  const currentRows = useMemo(() => {
    const offset = currentPage * rowsPerPage;
    return data.slice(offset, offset + rowsPerPage);
  }, [currentPage, data, rowsPerPage]);

  const pageCount = Math.ceil(data.length / rowsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <>
      {/* Render the table rows through the passed function */}
      <div className="table-container">
        {renderTableRows(currentRows)}
      </div>
      {/* Pagination and actions */}
      <div className="d-flex justify-content-end align-items-center mb-3" style={{ marginTop: '0px' }}>
        {pageCount > 1 && (
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={'pagination mb-0 mt-0'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
        {/* Render custom buttons passed as children */}
        <div className="ms-2">
          {children}
        </div>
      </div>
    </>
  );
}

export default PaginationComponent;