// src/components/product_sql/SuppSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function SuppSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'suppid', label: 'ID' },
    { key: 'suppname', label: 'ผู้นำเข้า' },
  ];

  const fields = [
    { key: 'suppname', label: 'ผู้นำเข้า', required: true },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/suppsql/"
      columns={columns}
      itemName="ผู้นำเข้า"
      dataKey="suppname"
      idKey="suppid"
      pageName="supplier"
      storageKey="suppSqlTableSortState"
      cancelKey="suppcancel"
      fields={fields} // Adding fields prop
    />
  );
}

export default SuppSqlTable;