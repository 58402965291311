import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Optional: Using Bootstrap for styling
import CustomerTable from '../../../main_sql/CustomerTable'; // Adjust path as needed

function SellBillEditForm({ initialData, onSave, onClose }) {
    const [formData, setFormData] = useState({
      sellDate: '',
      customer: '',
      sellComment: '',
      billDiscount: '',
    });
  
    const [showCustomerModal, setShowCustomerModal] = useState(false); // State for customer modal visibility
  
    useEffect(() => {
      if (initialData) {
        setFormData({
          sellDate: initialData.sellbilldatetime || '',
          customer: initialData.nickname || '',  // Initialize with customer nickname
          sellComment: initialData.sellbillcomment || '',
          billDiscount: initialData.sellbilldiscount || '',
        });
      }
    }, [initialData]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    const handleCustomerSelect = (customer) => {
      // Update the customer field with the selected customer's nickname
      setFormData((prevData) => ({
        ...prevData,
        customer: customer.nickname,  // Set the selected customer's nickname
      }));
      setShowCustomerModal(false); // Close the modal
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
        // Ensure that if billDiscount is null or empty, it defaults to 0
        const updatedFormData = {
            ...formData,
            billDiscount: formData.billDiscount ? formData.billDiscount : 0
        };
      if (onSave) {
        // Ensure that formData is passed back to the parent component with the updated customer field
        onSave(updatedFormData); 
      }
    };
  
    return (
      <>
        <Modal show={true} onHide={onClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Sell Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="sellDate" className="form-label">
                  วันที่ขาย
                </label>
                <input
                  type="date"
                  id="sellDate"
                  name="sellDate"
                  value={formData.sellDate}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
  
              <div className="mb-3">
                <label htmlFor="customer" className="form-label">
                  ลูกค้า
                </label>
                <input
                  type="text"
                  id="customer"
                  name="customer"
                  value={formData.customer} // This should now reflect the selected customer nickname
                  onClick={() => setShowCustomerModal(true)} // Open customer modal on click
                  readOnly // Set read-only to prevent manual editing
                  className="form-control"
                  placeholder="Click to select a customer"
                />
              </div>
  
              <div className="mb-3">
                <label htmlFor="sellComment" className="form-label">
                  หมายเหตุ
                </label>
                <textarea
                  id="sellComment"
                  name="sellComment"
                  value={formData.sellComment}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter comment"
                  rows={3}
                />
              </div>
  
              <div className="mb-3">
                <label htmlFor="billDiscount" className="form-label">
                  ส่วนลดท้ายบิล
                </label>
                <input
                  type="number"
                  id="billDiscount"
                  name="billDiscount"
                  value={formData.billDiscount}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter Bill Discount"
                />
              </div>
  
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
              <Button variant="secondary" onClick={onClose} className="ms-2">
                Close
              </Button>
            </form>
          </Modal.Body>
        </Modal>
  
        {/* CustomerTable Modal */}
        <Modal show={showCustomerModal} onHide={() => setShowCustomerModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomerTable onSelect={handleCustomerSelect} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default SellBillEditForm;