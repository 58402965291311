// src/components/utils/CustomTable.js


import React, { useCallback, useState, useEffect } from 'react';
import PaginationComponent from '../components/Pagination';
import useSortable from '../hooks/useSortable';
import './CustomTable.css';
import { Modal, Button } from 'react-bootstrap';

function CustomTable({
  data,
  columns,
  rowsPerPage = 10,
  renderRowExpansion,
  resetPage,
  onRowClick,
  rowClassName,
  storageKey = 'customTableSortState',
  page,
  hideActions,
  showAddButton = false,
  onAddNew,
  showEditColumn = false,
  onEdit,
  onSelectionChange,
  selectable = false,
  selectedColumns,
  onColumnsChange,
  rowIdKey = 'id',
  footerData,
  tableClassName, // Add a new prop for custom table class
}) {
  const { sortedData, handleSort, sortColumn, sortDirection } = useSortable(
    data,
    columns,
    storageKey
  );

  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // State to manage selected rows
  const [selectedRows, setSelectedRows] = useState([]);

  // Define the 'select' column
  const selectColumn = selectable
    ? {
        key: 'select',
        label: (
          <input
            type="checkbox"
            checked={
              data.length > 0 &&
              data.every((item) => selectedRows.includes(item[rowIdKey]))
            }
            onChange={(e) => {
              if (e.target.checked) {
                const allIds = data.map((item) => item[rowIdKey]);
                setSelectedRows(allIds);
              } else {
                setSelectedRows([]);
              }
            }}
            // Remove stopPropagation to allow row clicks to toggle selection
          />
        ),
        render: (item) => (
          <input
            type="checkbox"
            checked={selectedRows.includes(item[rowIdKey])}
            onChange={(e) => handleCheckboxChange(e, item)}
            // Remove stopPropagation to allow row clicks to toggle selection
          />
        ),
        sortable: false,
        className: 'text-center',
      }
    : null;

  // Merge the 'select' column at the beginning if selectable
  const allColumns = selectable && selectColumn ? [selectColumn, ...columns] : columns;

  const handleRowSelection = useCallback(
    (rowData) => {
      console.log('handleRowSelection called with:', rowData);
      const id = rowData[rowIdKey];
      if (selectable) {
        if (selectedRows.includes(id)) {
          setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
        } else {
          setSelectedRows((prev) => [...prev, id]);
        }
      }
      setSelectedRowId(id);
      if (onRowClick) {
        console.log('Calling onRowClick with:', rowData);
        onRowClick(rowData);
      } else {
        console.log('onRowClick is undefined');
      }
    },
    [onRowClick, rowIdKey, selectedRows, selectable]
  );

  // Handle checkbox change
  const handleCheckboxChange = (e, rowData) => {
    const id = rowData[rowIdKey];
    if (e.target.checked) {
      setSelectedRows((prev) => [...prev, id]);
    } else {
      setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
    }
  };

  // Notify parent about selected rows
  useEffect(() => {
    if (selectable && onSelectionChange) {
      const selectedData = data.filter((item) =>
        selectedRows.includes(item[rowIdKey])
      );
      onSelectionChange(selectedData);
    }
  }, [selectedRows,data, onSelectionChange, selectable, rowIdKey]);

  // Handle column selection changes
  const handleColumnChange = (columnKey) => {
    const newSelectedColumns = selectedColumns.includes(columnKey)
      ? selectedColumns.filter((col) => col !== columnKey)
      : [...selectedColumns, columnKey];
    onColumnsChange(newSelectedColumns);
  };

  const renderTableRows = useCallback(
    (currentRows) => (
      <div className="table-container">
        <table className={`table table-striped ${tableClassName || ''}`}> 
          <thead>
            <tr>
              {allColumns.map((column) => {
                if (selectedColumns.includes(column.key) || column.key === 'select') {
                  const isSortable = column.sortable !== false;
                  // Prevent sorting on 'select' column
                  const canSort = isSortable && column.key !== 'select';
                  return (
                    <th
                      key={column.key}
                      onClick={(e) => {
                        if (!isEditing && canSort && e.target === e.currentTarget) {
                          handleSort(column.key);
                        }
                      }}
                      style={{
                        cursor: isEditing
                          ? 'not-allowed'
                          : canSort
                          ? 'pointer'
                          : 'default',
                      }}
                      className={column.headerClassName}
                    >
                      {column.label}
                      {canSort &&
                        sortColumn === column.key &&
                        (sortDirection === 'asc' ? ' ▼' : ' ▲')}
                    </th>
                  );
                }
                return null;
              })}
              {showAddButton ? ( // If showAddButton is true, only render the Add button column
                <th>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onAddNew) {
                        onAddNew();
                      }
                    }}
                  >
                    +
                  </button>
                </th>
              ) : (
                showEditColumn && <th></th> // Otherwise, render the Edit column header
              )}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((rowData, rowIndex) => (
              <React.Fragment key={rowData[rowIdKey] || rowIndex}>
                <tr
                  onClick={() => handleRowSelection(rowData)} // Handle row click for selection
                  className={`${
                    rowClassName ? rowClassName(rowData) : ''
                  } ${selectedRowId === rowData[rowIdKey] ? 'selected-row' : ''} ${
                    isEditing && selectedRowId === rowData[rowIdKey] ? 'editing-row' : ''
                  }`}
                  style={onRowClick ? { cursor: 'pointer' } : undefined}
                >
                  {allColumns.map((column) => {
                    if (selectedColumns.includes(column.key) || column.key === 'select') {
                      return (
                        <td key={column.key} className={column.className}>
                          {column.render ? column.render(rowData) : rowData[column.key]}
                        </td>
                      );
                    }
                    return null;
                  })}
                  {showEditColumn && (
                    <td style={{ verticalAlign: 'middle',
                                  maxWidth: '45px', // Set the maximum width here
                                  overflow: 'hidden', // Optional: Hide overflow content
                                  textOverflow: 'ellipsis', // Optional: Add ellipsis for overflow text
                                  whiteSpace: 'nowrap', // Optional: Prevent text wrapping
                                }} 
                        className="text-center">
                      <span
                        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onEdit) {
                            setIsEditing(true); // Enter editing mode
                            onEdit(rowData, () => setIsEditing(false)); // Pass a callback to exit editing mode
                          }
                        }}
                      >
                        Edit
                      </span>
                    </td>
                  )}
                </tr>
                {renderRowExpansion && renderRowExpansion(rowData)}
              </React.Fragment>
            ))}
          </tbody>
          {footerData && (
            <tfoot>
              <tr>
                {allColumns.map((column) => {
                  if (selectedColumns.includes(column.key) || column.key === 'select') {
                    const footerCellData = footerData[column.key];
                    const footerContent = footerCellData ? footerCellData.content : '';
                    const footerClassName = footerCellData ? footerCellData.className : '';
                    return (
                      <td
                        key={column.key}
                        className={`footer-cell footer-cell-${column.key} ${footerClassName} ${column.className || ''}`}
                      >
                        {footerContent}
                      </td>
                    );
                  }
                  return null;
                })}
                {showEditColumn && (
                  <td className="footer-cell footer-cell-editcolumn"> {/* Add custom classes here */}
                  {footerData && footerData['edit'] ? footerData['edit'] : ''}
                </td>)}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    ),
    [
      allColumns,
      handleSort,
      onRowClick,
      renderRowExpansion,
      rowClassName,
      selectedColumns,
      sortColumn,
      sortDirection,
      selectedRowId,
      handleRowSelection,
      isEditing,
      showEditColumn,
      onEdit,
      showAddButton,
      onAddNew,
      rowIdKey,
      footerData,
      tableClassName,
    ]
  );

  return (
    <div>
          <PaginationComponent
            data={sortedData}
            rowsPerPage={rowsPerPage}
            renderTableRows={renderTableRows}
            resetPage={resetPage}
          >
            {/* Pass the 'Columns' button as a child */}
            {!hideActions && (
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => setShowColumnSelector(true)}
              >
                Columns
              </button>
            )}
          </PaginationComponent>

          {/* Column Selector Modal */}
          <Modal
            show={showColumnSelector}
            onHide={() => setShowColumnSelector(false)}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Columns to Display</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {columns.map((column) => (
                <div key={column.key} className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`column-${column.key}`}
                    checked={selectedColumns.includes(column.key)}
                    onChange={() => handleColumnChange(column.key)}
                  />
                  <label className="form-check-label" htmlFor={`column-${column.key}`}>
                    {column.label}
                  </label>
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowColumnSelector(false)}>
                Save Changes
              </Button>
              <Button variant="secondary" onClick={() => setShowColumnSelector(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
         

export default CustomTable;