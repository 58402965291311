import React from 'react';
import SqlTable from '../product_sql/SqlTable'; // Import the SqlTable component

function CustomerTable({ onSelect, onClose }) {
  const columns = [
    { key: 'idcustomer', label: 'ID' },
    { key: 'nickname', label: 'Nickname' },
    { key: 'tel', label: 'Telephone' },
  ];

  const fields = [
    { key: 'name_lastname', label: 'ชื่อ-นามสกุล', required: false },
    { key: 'nickname', label: 'ชื่อเล่น', required: true },
    { key: 'tel', label: 'เบอร์โทร', required: true },
    { key: 'address', label: 'ที่อยู่', required: false },
    { key: 'custcomment', label: 'หมายเหตุ', required: false },
    { key: 'custgender', label: 'เพศ', required: false },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/Customersql/" // Endpoint for fetching customer data
      columns={columns}
      itemName="ลูกค้า" // Item name for display
      dataKey="nickname" // Key used for filtering and displaying customer name
      idKey="idcustomer" // Primary key for the item
      pageName="customer" // Page identifier for Redux state
      storageKey="customerTableSortState" // Key to store column state in localStorage
      cancelKey="custcancel" // Field to identify canceled items
      fields={fields} // Pass the fields to SqlTable
    />
  );
}

export default CustomerTable;