//src/components/service_page/service_page/service_sql/JobStatusTable.js

import React from 'react';
import GenericSqlTable from '../../GenericSqlTable';

function JobStatusTable({ onSelect }) {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/Jobstatusnamesql/`; // Replace with your actual endpoint

  const columns = [
    { key: 'idjobstatus', label: 'ID' },
    { key: 'jobstatusname', label: 'Job Status' },
  ];

  return (
    <GenericSqlTable
      apiUrl={apiUrl}
      title="สถานะ" // Adjust the title as needed
      onSelect={onSelect}
      idKey="idjobstatus"        // Replace with the actual ID field in your data
      nameKey="jobstatusname"    // Replace with the actual name field in your data
      columns={columns}
      searchKey="jobstatusname"  // Replace with the actual field to search by
    />
  );
}

export default JobStatusTable;