// src/components/sell_page/sellview/SellBillViewPage.js

import React, { useState, useEffect } from 'react';
import SellBillViewTable from './SellBillViewTable';
import SellProdViewTable from './SellProdViewTable';
import SellServViewTable from './SellServViewTable';
import SellJobViewTable from './SellJobViewTable';
import { getToday } from '../../../utils/dateFunc'; // Import CustomerTable

function SellBillViewPage() {
  const [selectedSellBillId, setSelectedSellBillId] = useState(null);
  const [selectedSellServId, setSelectedSellServId] = useState(null);
  const [dataVersion, setDataVersion] = useState(0);

  const todayThai = getToday();
  const [filters, setFilters] = useState({
    dateFrom: todayThai,
    dateTo: todayThai,
    customerName: '',
    billComment: '',
    showAll: false,
  });

  const handleSellBillSelect = (sellBillId) => {
    setSelectedSellBillId(sellBillId);
    setSelectedSellServId(null); // Reset selected service when a new bill is selected
    setDataVersion(prevVersion => prevVersion + 1); // Increment dataVersion to trigger refresh
  };

  const handleSellServSelect = (sellServId) => {
    setSelectedSellServId(sellServId);
  };

  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  // Add a function to handle data changes
  const handleDataChange = () => {
    setDataVersion(prevVersion => prevVersion + 1);
  };

  // Watch `filters` and update `dataVersion` on change
  useEffect(() => {
    setDataVersion((prevVersion) => prevVersion + 1);
  }, [filters]);
  
  return (
    <div>
      <SellBillViewTable 
      onSellBillSelect={handleSellBillSelect}
      onDataChange={handleDataChange} // Pass this function to child
      filters={filters} 
      onFilterChange={handleFilterChange} 
      />
        <SellProdViewTable 
        sellBillId={selectedSellBillId} 
        showAll={filters.showAll}
        dataVersion={dataVersion} // Pass dataVersion to trigger refresh
        onDataChange={handleDataChange}
        />
        <SellServViewTable 
        onSellServSelect={handleSellServSelect}
        sellBillId={selectedSellBillId} 
        showAll={filters.showAll}
        dataVersion={dataVersion} // Pass dataVersion to trigger refresh
        onDataChange={handleDataChange}
        />
      {selectedSellServId && (
        <SellJobViewTable 
        sellServId={selectedSellServId} 
        showAll={filters.showAll}
        dataVersion={dataVersion} // Pass dataVersion to trigger refresh
        />
      )}


    </div>
  );
}

export default SellBillViewPage;