// src/redux/slices/productToSellSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [], // Array of products to sell
};

const productToSellSlice = createSlice({
  name: 'productToSell',
  initialState,
  reducers: {
    addProducts: (state, action) => {
        const newProducts = action.payload.filter(
          (newProd) => !state.products.some((prod) => prod.prodid === newProd.prodid)
        ).map((prod) => ({
          ...prod,
          pricePerUnit: prod.price || 0,
          discountPerUnit: 0,
          quantity: 1,
          amount: prod.price || 0,
        }));
      
        state.products = [...state.products, ...newProducts];
      },
    updateProduct: (state, action) => {
        const { prodid, field, value } = action.payload;
        const product = state.products.find((prod) => prod.prodid === prodid);
        if (product) {
          product[field] = value;
          // Recalculate amount
          const price = parseFloat(product.pricePerUnit) || 0;
          const discount = parseFloat(product.discountPerUnit) || 0;
          const qty = parseInt(product.quantity) || 0;
          product.amount = (price - discount) * qty;
        }
      },
    removeProduct: (state, action) => {
      state.products = state.products.filter((prod) => prod.prodid !== action.payload);
    },
    clearProducts: (state) => {
      state.products = [];
    },
  },
});

export const { addProducts, updateProduct, removeProduct, clearProducts } = productToSellSlice.actions;

export default productToSellSlice.reducer;