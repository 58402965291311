import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../utils/apiClient';
import PaginationComponent from '../Pagination';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button components
import './SellServTable.css'; // Import the custom CSS file

function SellServTable({ sellServData, fetchSellServData ,fetchSellJobData, onRowClick, onDeleteRow, sellJobData, onTotalSumChange}) {
  const [data, setData] = useState([]);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedRowId, setSelectedRowId] = useState(null); // State to track the selected row

  // State for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // Initialize data from sellServData when the component mounts
  useEffect(() => {
    if (sellServData) {
      // Create a mapping from servsellidto to totalSum
      const sumJobPriceMap = {};
      if (sellJobData) {
        sellJobData.forEach((job) => {
          const servsellidto = job.servsellidto;
          const qty = Number(job.servjobqtytoadd) || 0;
          const price = Number(job.servjobpricetoadd) || 0;
          const discount = Number(job.servjobdisctoadd) || 0;
          const total = (price - discount) * qty;
          if (sumJobPriceMap[servsellidto]) {
            sumJobPriceMap[servsellidto] += total;
          } else {
            sumJobPriceMap[servsellidto] = total;
          }
        });
      }
  
      const fetchedData = sellServData.map((item) => ({
        ...item,
        sumJobPrice: sumJobPriceMap[item.idserviceselltoadd] || 0,
      }));
      setData(fetchedData);
    }
  }, [sellServData, sellJobData]);

  // Memoize sortedData to prevent unnecessary recalculations
  const sortedData = useMemo(() => {
    let sorted = [...data];
    if (sortColumn) {
      sorted.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        // Handle undefined values
        if (aValue === undefined || aValue === null) return 1;
        if (bValue === undefined || bValue === null) return -1;

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [data, sortColumn, sortDirection]);

  // Handle sorting
  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortColumn === columnKey && sortDirection === 'asc') {
      direction = 'desc';
    }
    setSortColumn(columnKey);
    setSortDirection(direction);
  };

  // Open the delete confirmation modal
  const openDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  // Close the delete confirmation modal
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedRow(null);
  };

  // Handle row click to change the background color
  const handleRowClick = (idserviceselltoadd) => {
    setSelectedRowId(idserviceselltoadd);
    if (onRowClick) {
      onRowClick(idserviceselltoadd);
    }
  };

  // Log the selectedRowId whenever it changes
  useEffect(() => {
    console.log('Selected Row ID:', selectedRowId);
  }, [selectedRowId]);

  // Function to format numbers with commas
  function formatNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Calculate the total sum
  const totalSum = useMemo(() => {
    return data.reduce((acc, row) => acc + (row.sumJobPrice), 0);
  }, [data]);

    // Notify parent component of totalSum changes
    useEffect(() => {
      if (onTotalSumChange) {
        onTotalSumChange(totalSum);
      }
    }, [totalSum, onTotalSumChange]);

  // Define the function to render table rows for the PaginationComponent
  const renderTableRows = (currentRows) => (
    <table className="table table-striped table-bordered" key={selectedRowId}>
      <thead>
        <tr>
          <th onClick={() => handleSort('categoryname')}>
            กลุ่ม {sortColumn === 'categoryname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('brandname')}>
            ยี่ห้อ {sortColumn === 'brandname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('modelname')}>
            รุ่น {sortColumn === 'modelname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('sizename')}>
            ไซส์ {sortColumn === 'sizename' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('colorname')}>
            สี {sortColumn === 'colorname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('sumJobPrice')}>
            ค่าบริการ {sortColumn === 'sumJobPrice' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th style={{ width: '10px' }}></th>
        </tr>
      </thead>
      <tbody>
        {currentRows.length > 0 ? (
          currentRows.map((row) => (
            <tr
              key={row.idserviceselltoadd}
              className={selectedRowId === row.idserviceselltoadd ? 'selected-row' : ''}
              onClick={() => handleRowClick(row.idserviceselltoadd)}
            >
              <td>{row.categoryname}</td>
              <td>{row.brandname}</td>
              <td>{row.modelname}</td>
              <td>{row.sizename}</td>
              <td>{row.colorname}</td>
              <td style={{ textAlign: 'right' }}>{formatNumberWithCommas(row.sumJobPrice)}</td>
              <td>
                <span style={{ color: 'red', cursor: 'pointer' }} onClick={(e) => {
                              e.stopPropagation(); // Prevent the click from triggering row selection
                              openDeleteModal(row);
                            }}>
                  ลบ
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6" style={{ textAlign: 'center' }}>
              No Service Data Found
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
            <tr>
              <td colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                รวมค่าบริการ
              </td>
              <td style={{ textAlign: 'right' }}>{totalSum.toLocaleString()}</td>
              <td></td>
            </tr>
          </tfoot>
    </table>
  );

// Function to handle row deletion
  const handleDelete = async () => {
    if (selectedRow) {
      try {
        const serviceId = selectedRow.idserviceselltoadd;

        // First, delete the service sell
        await axiosInstance.delete(`/Serviceselltoaddsql/${serviceId}/`);

        // Then, get the jobs associated with this service sell
        const response = await axiosInstance.get(`/Servjobtoaddsql/?servsellidto=${serviceId}`);
        const jobsToDelete = response.data;

        // Delete each job associated with the service
        const deleteJobPromises = jobsToDelete.map((job) =>
          axiosInstance.delete(`/Servjobtoaddsql/${job.idservjobtoadd}/`)
        );
        await Promise.all(deleteJobPromises);

        // Update the local data
        setData((prevData) =>
          prevData.filter((row) => row.idserviceselltoadd !== serviceId)
        );
        setShowDeleteModal(false); // Close the modal after deletion
        setSelectedRow(null); // Reset selected row

        // Fetch updated data
        fetchSellServData(); // Fetch updated service data
        fetchSellJobData(); // Fetch updated job data

        // Notify parent component about the deletion
        if (onDeleteRow) {
          onDeleteRow(serviceId);
        }
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }
  };

  return (
    <div>
      <PaginationComponent
        data={sortedData}
        rowsPerPage={rowsPerPage}
        renderTableRows={renderTableRows}
      />
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <p>
              Are you sure you want to delete: {selectedRow.servprodcattoadd} {selectedRow.servprodbrandtoadd} {selectedRow.servprodmodeltoadd}?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SellServTable;