// src/components/adjust_page/FilteradjTable.js

import React from 'react';

function FilteradjTable({ filters, handleFilterChange, handleClearFilters, handleRefresh }) {
  return (
    <div className="mb-3">
      <form className="filter-adj-table-form">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="กลุ่ม"
              name="catname" 
              value={filters.catname}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ย่อย"
              name="typename"
              value={filters.typename}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ยี่ห้อ"
              name="brandname"
              value={filters.brandname}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="รุ่น"
              name="modelname"
              value={filters.modelname}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ไซส์"
              name="sizename"
              value={filters.sizename}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="สี"
              name="colorname"
              value={filters.colorname}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="BC"
              name="barcode"
              value={filters.barcode}
              onChange={handleFilterChange}
            />
          
            <button type="button" className="btn btn-secondary" onClick={handleClearFilters}>
            Clear
          </button>
        
          </div>
        </div>
      </form>
    </div>
  );
}

export default FilteradjTable;