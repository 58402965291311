//src/utils/DoWithLineModal.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function DoWithLineModal({
  show,
  onHide,
  isDeleteConfirmation,
  itemName,
  onEdit,
  onCreateNew,
  onDelete,
  onConfirmDelete,
  onCancelDeleteConfirmation,
  showCreateNew = true,
}) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {isDeleteConfirmation ? 'ยืนยันการลบ' : 'ต้องการทำอะไรกับรายการนี้'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isDeleteConfirmation ? (
          <p>ต้องการลบรายการ : {itemName} ใช่หรือไม่?</p>
        ) : (
          <>
            <Button variant="primary" onClick={onEdit}>
              แก้ไข
            </Button>
            {showCreateNew && (
              <Button variant="secondary" className="ms-2" onClick={onCreateNew}>
                นำไปสร้างใหม่
              </Button>
            )}
            <Button variant="danger" className="ms-2" onClick={onDelete}>
              ลบ
            </Button>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isDeleteConfirmation ? (
          <>
            <Button variant="danger" onClick={onConfirmDelete}>
              ใช่
            </Button>
            <Button variant="secondary" onClick={onCancelDeleteConfirmation}>
              ไม่ใช่
            </Button>
          </>
        ) : (
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DoWithLineModal;