// src/components/product_sql/ColorSqlTable.js

import React from 'react';
import SqlTable from './SqlTable';

function ColorSqlTable({ onSelect, onClose }) {
  const columns = [
    { key: 'idprodcolor', label: 'ID' },
    { key: 'colorname', label: 'สี' },
  ];

  const fields = [
    { key: 'colorname', label: 'สี', required: true },
  ];

  return (
    <SqlTable
      onSelect={onSelect}
      onClose={onClose}
      endpoint="/colorsql/"
      columns={columns}
      itemName="สี"
      dataKey="colorname"
      idKey="idprodcolor"
      pageName="color"
      storageKey="colorSqlTableSortState"
      cancelKey="colorcancel"
      fields={fields} // Adding fields prop
    />
  );
}

export default ColorSqlTable;