import React, { useState } from 'react';
import axiosInstance from '../../utils/apiClient';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match.');
      return;
    }

    try {
      await axiosInstance.post('/change-password/', {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });

      setSuccessMessage('Password changed successfully.');
      setError('');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');

      // Optionally, navigate to another page
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Failed to change password.');
      }
    }
  };

  return (
    <div className="change-password-container">
      <h2>เปลี่ยน Password</h2>
      {error && <p className="error-text">{error}</p>}
      {successMessage && <p className="success-text">{successMessage}</p>}
      <form onSubmit={handleChangePassword}>
        <div className="input-container">
          <input
            type="password"
            placeholder="Password เดิม"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="input-container">
          <input
            type="password"
            placeholder="Password ใหม่"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="input-container">
          <input
            type="password"
            placeholder="ยืนยัน Password ใหม่"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button className="change-password-button" type="submit">
          บันทึก Password
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;