// src/components/sell_page/sellPage.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../../utils/apiClient';
import SellProdTable from './SellProdTable';
import SellServTable from './SellServTable';
import SellJobTable from './SellJobTable';
import SellBillForm from './SellBillForm';
import FilterForm from '../FilterForm'; 
import StockTable from '../StockTable'; 
import ServThingAddModal from '../service_page/ServThingAddModal'; 
import ServiceJobAddModal from '../service_page/ServiceJobAddModal'; 
import { Modal } from 'react-bootstrap';
import { getToday } from '../../utils/dateFunc';


function SellPage() {
  const [sellFormData, setSellFormData] = useState({
    sellComment: localStorage.getItem('sellComment') || '',
    sellDate: localStorage.getItem('sellDate') || getToday(), // Default to today's date
    customer: localStorage.getItem('customer') || '',
    customerId: localStorage.getItem('customerId') || '',
    sellbilldiscount: parseInt(localStorage.getItem('sellbilldiscount')) || 0,
  });

  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showAddServModal, setShowAddServModal] = useState(false);
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [stockData, setStockData] = useState([]); 
  const [filters, setFilters] = useState({
    name: '',
    categoryname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    barcode: '',
    suppname: '',
  });
  const [seeAll, setSeeAll] = useState(false);
  const [sellProdData, setsellProdData] = useState([]); // State to store added products
  const [sellServData, setsellServData] = useState([]); // State to store added products
  const [sellJobDataAll, setSellJobDataAll] = useState([]);
  const [sellJobData, setsellJobData] = useState([]); // State to store added products
  const [selectedProduct, setSelectedProduct] = useState([]); // selected product in modal
  const [selectedServiceSellId, setSelectedServiceSellId] = useState(null);
  const [totalProdSell, setTotalProdSell] = useState(0);
  const [totalServSell, setTotalServSell] = useState(0);
  const netTotal = totalProdSell + totalServSell - (sellFormData.sellbilldiscount || 0);

  const fetchSellProdData = async () => {
    try {
      const response = await axiosInstance.get('/Sellprodtoaddv/');
      setsellProdData(response.data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching sellProdData:', error);
    }
  };

  const fetchSellServData = async () => {
    try {
      const response = await axiosInstance.get('/Sellservtoaddv/');
      setsellServData(response.data); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching sellServData:', error);
    }
  };

  const fetchSellJobData = async () => {
    try {
      const response = await axiosInstance.get('/Servjobtoaddv/');
      setSellJobDataAll(response.data);
    } catch (error) {
      console.error('Error fetching sellJobData:', error);
    }
  };


  const fetchStockData = useCallback(() => {
    axiosInstance.get(`/stview/`)
      .then(response => {
        setStockData(response.data); 
      })
      .catch(error => {
        console.error('There was an error fetching the stock data!', error);
      });
  }, []);

  useEffect(() => {
    if (showAddProductModal) {
      fetchStockData(); 
    }
  }, [fetchStockData, showAddProductModal]);

  useEffect(() => {
    // Fetch sellProdData when the component mounts
    fetchSellProdData();
    fetchSellServData();
    fetchSellJobData();
  }, []);

  useEffect(() => {
    if (selectedServiceSellId) {
      const filteredJobs = sellJobDataAll.filter(
        (job) => job.servsellidto === selectedServiceSellId
      );
      setsellJobData(filteredJobs);
    } else {
      setsellJobData([]);
    }
  }, [selectedServiceSellId, sellJobDataAll]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleClearFilters = () => {
    setFilters({
      name: '',
      categoryname: '',
      typename: '',
      brandname: '',
      modelname: '',
      sizename: '',
      colorname: '',
      barcode: '',
      suppname: '',
    });
    fetchStockData(); 
  };

  const handleRefresh = () => {
    fetchStockData(); 
  };

  const handleSeeAllChange = () => {
    setSeeAll(!seeAll);
  };

  const filteredStockData = useMemo(() => {
    return stockData.filter(item => {
      const matchesFilters = (
        (item.name || '').toLowerCase().includes(filters.name.toLowerCase()) &&
        (item.categoryname || '').toLowerCase().includes(filters.categoryname.toLowerCase()) &&
        (item.typename || '').toLowerCase().includes(filters.typename.toLowerCase()) &&
        (item.brandname || '').toLowerCase().includes(filters.brandname.toLowerCase()) &&
        (item.modelname || '').toLowerCase().includes(filters.modelname.toLowerCase()) &&
        (item.sizename || '').toLowerCase().includes(filters.sizename.toLowerCase()) &&
        (item.colorname || '').toLowerCase().includes(filters.colorname.toLowerCase()) &&
        (item.barcode || '').toLowerCase().includes(filters.barcode.toLowerCase()) &&
        (item.suppname || '').toLowerCase().includes(filters.suppname.toLowerCase())
      );
      const matchesSeeAll = seeAll || (item.st > 0 && !item.prodcancel);
      return matchesFilters && matchesSeeAll;
    });
  }, [stockData, filters, seeAll]);


  const handleAddProduct = () => {
    setShowAddProductModal(true);
  };

  const handleAddServ = () => {
    setShowAddServModal(true);
  };
  
  const handleAddJob = () => {
    setShowAddJobModal(true);
  };

  const handleCloseModal = () => {
    setShowAddProductModal(false);
  };

  // Function to handle adding a single selected product
  const handleAddSelectedToSell = () => {
    if (!selectedProduct || selectedProduct.length === 0) {
      alert("Please select a product to add.");
      return;
    }
  
    const requests = selectedProduct.map(async (product) => {
      const dataToAdd = {
        sellprodidtoadd: product.prodid,
        sellprodqtytoadd: 1, // Set default qty to 1
        sellprodpricetoadd: product.price, // Set price from StockTable
      };
      console.log("Selected Products:", selectedProduct); // Add this line
  // ... rest of your code
  
      try {
        const response = await axiosInstance.post('/Sellprodtoadd/', dataToAdd);
        return response.data;
      } catch (error) {
        console.error('Error adding product to Sellprodtoadd:', error);
        return null;
      }
    });
  
    Promise.all(requests).then((results) => {
      fetchSellProdData();
      handleCloseModal();
    });
  };

  // Handle saving the sell
  const handleSaveSell = async () => {
    try {
      const customerId = sellFormData.customerId ? parseInt(sellFormData.customerId, 10) : 13;
      // Get sellbilldiscount from localStorage or use the value from sellFormData as a fallback
      const storedDiscount = localStorage.getItem('sellbilldiscount');
      const sellbilldiscount = storedDiscount !== null ? parseInt(storedDiscount, 10) : (sellFormData.sellbilldiscount || 0);

    console.log("Sell Form Data:", {
      sellbillcomment: sellFormData.sellComment,
      sellbilldatetime: sellFormData.sellDate,
      sellbillcustomerid: customerId,
      sellbilldiscount: sellbilldiscount,
    });
      // Step 1: Create sellbillsql record
      const sellBillResponse = await axiosInstance.post('/Sellbillsql/', {
        sellbillcomment: sellFormData.sellComment? sellFormData.sellComment : '-',
        sellbilldatetime: sellFormData.sellDate,
        sellbillcustomerid: customerId,
        sellbilldiscount: sellbilldiscount
        //adjstaff: 1 // Replace with actual staff ID if available
      });
  
      const idsellbill = sellBillResponse.data.idsellbill; // Get idadjbill from the created record

          // Step 2: Fetch data from Sellprodtoadd (which contains the selected products)
      const response = await axiosInstance.get('/Sellprodtoadd/');
      const sellProdToAddData = response.data;
      console.log("SellProdToAdd Data:", sellProdToAddData);


      // Step 3: Prepare SellProd data
      const sellProdArray = sellProdToAddData.map((item) => ({
        sellprodid: item.sellprodidtoadd,
        sellbillidto: idsellbill,
        sellprodqty: item.sellprodqtytoadd? item.sellprodqtytoadd : 0,
        sellprodprice: item.sellprodpricetoadd? item.sellprodpricetoadd : 0,
        sellproddis: item.sellproddistoadd? item.sellproddistoadd : 0,
        sellprodtimestamp: new Date().toISOString(),
      }));
      console.log("Sell Product Array:", sellProdArray);
      // Step 4 Create SellProd records
      await axiosInstance.post('/Sellprodsql/bulk_create/', sellProdArray);
  

      // Step 5: Fetch data from Serviceselltoadd (which contains the selected services)
      const responseserv = await axiosInstance.get('/Serviceselltoaddsql/');
      const sellServToAddData = responseserv.data;
      console.log("SellServToAdd Data:", sellServToAddData);

      // Step 6: Prepare SellServ data
      const sellServArray = sellServToAddData.map((item) => ({
        idserviceselltoadd: item.idserviceselltoadd,
        servtosellbillid: idsellbill,
        servprodcat: item.servprodcattoadd,
        servprodbrand: item.servprodbrandtoadd,
        servprodmodel: item.servprodmodeltoadd,
        servprodsize: item.servprodsizetoadd,
        servprodcolor: item.servprodcolortoadd,
        servcomment: item.servcommenttoadd,
        servtimestamp: new Date().toISOString(),
        servcancel: false, // Add this line
      }));
      console.log("Sell Service Array:", sellServArray);

      // Step 7: Create SellServ records and get the response
      const sellServResponse = await axiosInstance.post('/Servicesellsql/bulk_create/', sellServArray);
      console.log("SellServResponse:", sellServResponse.data);

      // Assuming sellServResponse.data is an array of created services with idservicesell and idserviceselltoadd
      const createdServices = sellServResponse.data;
      console.log("Created Services:", createdServices);
            // Create a mapping from idserviceselltoadd to idservicesell
      const serviceIdMapping = {};
      createdServices.forEach((service) => {
        serviceIdMapping[service.idserviceselltoadd] = service.idservicesell;
      });
      console.log("Service ID Mapping:", serviceIdMapping);

      // Step 8: Fetch data from Servjobtoadd (which contains the selected jobs)
      const responsejob = await axiosInstance.get('/Servjobtoaddsql/');
      const sellJobToAddData = responsejob.data;
      console.log("SellJobToAdd Data:", sellJobToAddData);

      // Step 9: Prepare SellJob data
      const sellJobArray = sellJobToAddData.map((item) => ({
        servjobtosellbillid: idsellbill,
        servsellid: serviceIdMapping[item.servsellidto],  // Use the mapping here
        servjobname: item.servjobnametoadd,
        servjobcomment: item.servjobcommenttoadd,
        servjobprice: item.servjobpricetoadd,
        servjobdisc: item.servjobdisctoadd,
        servjobqty: item.servjobqtytoadd,
        servjobtimestamp: new Date().toISOString(),
        servjobcancle: false, // Add this line
      }));
      console.log("Sell Job Array:", sellJobArray);

      // Step 10: Create SellJob records
      await axiosInstance.post('/Servjobsql/bulk_create/', sellJobArray);

      // Step 11: Clear the SellProdToAdd table after saving
      await axiosInstance.post('/Sellprodtoadd/delete-all/');
      setsellProdData([]); // Clear local data

      // Step 12: Clear the Serviceselltoadd table after saving
      await axiosInstance.post('/Serviceselltoaddsql/delete-all/');
      setsellServData([]); // Clear local data

      // Step 13: Clear the Servjobtoadd table after saving
      await axiosInstance.post('/Servjobtoaddsql/delete-all/');
      setsellJobData([]); // Clear local data

    // Step 14: Clear local storage values
    localStorage.removeItem('sellComment');
    localStorage.removeItem('customer');
    localStorage.removeItem('customerId');
    localStorage.removeItem('sellDate');
    localStorage.removeItem('sellbilldiscount');

    // Step 12: Reset the form fields
    setSellFormData({
      sellComment: '',
      sellDate: getToday(),
      customer: '',
      customerId: '',
      sellbilldiscount: 0,
    });
      
      alert('Save sell bill success');
      window.location.reload(); // This will refresh the page
    } catch (error) {
      console.error('Error saving sell bill:', error);
      alert('Error saving sell bill');
    }
  };

  // Handle comment change from AdjBillToAddForm
  const handleSellCommentChange = (updatedData) => {
    setSellFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  // Handle discount input change
  const handleDiscountChange = (value) => {
    const numericValue = parseInt(value.replace(/,/g, ''), 10) || 0;
    const updatedData = {
      ...sellFormData,
      sellbilldiscount: numericValue,
    };
    setSellFormData(updatedData);
    localStorage.setItem('sellbilldiscount', numericValue);
  };

  const handleClearAll = useCallback(async () => {
    const confirmation = window.confirm('Are you sure you want to clear all products and services?');
    if (confirmation) {
      try {
        // Clear all products in Sellprodtoadd
        await axiosInstance.post('/Sellprodtoadd/delete-all/');
        setsellProdData([]); // Clear the product data in the table
  
        // Clear all services in Serviceselltoaddsql
        await axiosInstance.post('/Serviceselltoaddsql/delete-all/');
        setsellServData([]); // Clear the service data in the table
  
        // Clear all jobs in Servjobtoaddsql
        await axiosInstance.post('/Servjobtoaddsql/delete-all/');
        setsellJobData([]); // Clear the job data in the table
  
        // Reset the sell bill form fields
        setSellFormData({
          sellComment: '',
          sellDate: getToday(), // Reset to today's date
          customer: '',
          customerId: '',
          sellbilldiscount: 0,
        });
  
          // Clear localStorage values
          localStorage.removeItem('customer');
          localStorage.removeItem('customerId');
          localStorage.removeItem('sellComment');
          localStorage.removeItem('sellDate');
          localStorage.removeItem('sellbilldiscount');
  
        window.location.reload(); // This will refresh the page
      } catch (error) {
        console.error('Error clearing all rows and resetting the form:', error);
      }
    }
  }, []);

  //handle service add
  // Step 1: Prepare Serv prod data
  const handleAddThingConfirm = async (formData) => {
      const formDataToSubmit = {
        ...formData,
        servprodcattoadd: formData.servprodcat ? formData.servprodcat : null,
        servprodcolortoadd: formData.servprodcolor ? formData.servprodcolor : null,
        servprodbrandtoadd: formData.servprodbrand ? formData.servprodbrand : null,
        servprodmodeltoadd: formData.servprodmodel ? formData.servprodmodel : null,
        servprodsizetoadd: formData.servprodsize ? formData.servprodsize : null,
      };
      console.log('Data being sent:', formDataToSubmit);

      await axiosInstance.post(`/Serviceselltoaddsql/`, formDataToSubmit);

      fetchSellServData(); // Fetch updated data
      setShowAddServModal(false); // Close the modal after saving
  // Step 5: Clear local storage values
      localStorage.removeItem('sellComment');
      localStorage.removeItem('customer');
      localStorage.removeItem('customerId');
      localStorage.removeItem('sellbilldiscount');
  };


    //handle service add
  // Step 1: Prepare Serv job data
  const handleAddjobConfirm = async (formData) => {
    const formDataToSubmit = {
      ...formData,
      servjobnametoadd: formData.idservicename ? formData.idservicename : null,
      servjobcommenttoadd: formData.servjobcomment ? formData.servjobcomment : null,
      servjobpricetoadd: formData.servjobprice ? formData.servjobprice : 0,
      servjobdisctoadd: formData.servjobdisc ? formData.servjobdisc : 0,
      servjobqtytoadd: formData.servjobqty ? formData.servjobqty : 1,
      servsellidto: selectedServiceSellId, // Include this field
      };
      console.log('Data being sent:', formDataToSubmit);

      await axiosInstance.post(`/Servjobtoaddsql/`, formDataToSubmit);

      fetchSellJobData(); // Fetch updated data
      setShowAddJobModal(false); // Close the modal after saving
  // Step 5: Clear local storage values
      localStorage.removeItem('servjobname');
      localStorage.removeItem('servjobcomment');
      localStorage.removeItem('servjobprice');
      localStorage.removeItem('servjobdisc');
      localStorage.removeItem('servjobqty');


      };

  const onCancel = () => {
    setShowAddServModal(false);
    setShowAddJobModal(false);
  };

  const handleServiceSellDelete = (deletedId) => {
    if (deletedId === selectedServiceSellId) {
      setSelectedServiceSellId(null);
      setsellJobData([]); // Clear the sellJobData for the deselected service
    }
    // Regardless of which service is deleted, update sellJobDataAll
    fetchSellJobData(); // Refresh all job data
  };
  function formatNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <div className="container mb-0 pt-1">
      <SellBillForm 
      onChange={handleSellCommentChange} 
      formData={sellFormData}
      />
      <div className="d-flex  align-items-center justify-content-between">
      <div className="d-flex mb-1">
        <h5 className="me-3">สินค้าขาย</h5>
        <button className="btn btn-primary me-2" onClick={handleAddProduct}>
          เพิ่มสินค้าขาย
        </button>
        <button className="btn btn-danger" onClick={handleClearAll}>
          เคลียหน้าขาย
        </button>
        </div>
        <div className="d-flex flex-row-reverse mb-1">
        <button className="btn btn-success" onClick={handleSaveSell}>
          บันทึก
        </button>
      </div>
      </div>
      <SellProdTable 
        sellProdData={sellProdData} 
        fetchSellProdData={fetchSellProdData} 
        handleClearAll={handleClearAll}
        onTotalSumChange={setTotalProdSell}
      />
      {/* Display totals and discount input */}
      <div className="d-flex mb-2 align-items-center justify-content-end">
        <h5 className="me-3">ยอดสินค้า : {formatNumberWithCommas(totalProdSell)}</h5>   
        <h5 className="me-3">ยอด service : {formatNumberWithCommas(totalServSell)}</h5>
        <h5 className="me-3">ส่วนลดท้ายบิล :</h5>   
        <input
          type="text"
          value={formatNumberWithCommas(sellFormData.sellbilldiscount || 0)}
          onChange={(e) => handleDiscountChange(e.target.value)}
          onFocus={(e) => e.target.value = sellFormData.sellbilldiscount || ''}
          onBlur={(e) => e.target.value = formatNumberWithCommas(sellFormData.sellbilldiscount || 0)}
          className="me-3 mb-2"
          style={{
            width: `${(sellFormData.sellbilldiscount || 0).toString().length + 3.5}ch`,
            height: '32px',
            textAlign: 'right',
          }}
        />
        <h5 className="me-1">รวมยอดบิล : </h5> <h3>{formatNumberWithCommas(netTotal)}</h3>
      </div>
      <div className="d-flex mb-2 align-items-center">
        <h5 className="me-3">ของนำมาซ่อม</h5>     
        <button className="btn btn-primary me-2" onClick={handleAddServ}>
          เพิ่มของซ่อม
        </button>
      </div>
      <SellServTable
        sellServData={sellServData}
        sellJobData={sellJobDataAll} // Pass sellJobData here
        fetchSellServData={fetchSellServData}
        fetchSellJobData={fetchSellJobData} // Pass fetchSellJobData
        onRowClick={setSelectedServiceSellId}
        onDeleteRow={handleServiceSellDelete}
        onTotalSumChange={setTotalServSell}
      />
      {selectedServiceSellId && (
        <>
          <div className="d-flex mb-2"> 
          <h5 className="me-3">งานที่ทำ</h5>    
            <button className="btn btn-primary me-2" onClick={handleAddJob}>
              เพิ่มงานที่ทำ
            </button>
          </div>

          <SellJobTable
            sellJobData={sellJobData}
            fetchSellJobData={fetchSellJobData}
            selectedServiceSellId={selectedServiceSellId}
          />
        </>
      )}

      <Modal show={showAddProductModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilterForm
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleClearFilters={handleClearFilters}
            seeAll={seeAll}
            handleSeeAllChange={handleSeeAllChange}
            handleRefresh={handleRefresh}
          />
          <StockTable 
            data={filteredStockData} 
            seeAll={seeAll} 
            selectable={true}
            onSelectionChange={setSelectedProduct} // Capture selected products here          
            hideActions={true} // Pass hideActions as true when modal is displayed
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            ปิด  
          </button>
          <button className="btn btn-warning" onClick={handleAddSelectedToSell}>
            เพิ่มสินค้าขาย
          </button>
        </Modal.Footer>
      </Modal>
      
      <ServThingAddModal
        show={showAddServModal}
        handleConfirm={handleAddThingConfirm}
        onCancel={onCancel}
      />
      <ServiceJobAddModal
        show={showAddJobModal}
        handleConfirm={handleAddjobConfirm}
        onCancel={onCancel}
      />
  </div>
  );
}

export default SellPage;