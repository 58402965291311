// src/components/adjust_page/AdjBillToAddForm.js

import React from 'react';

function AdjBillToAddForm({ onChange }) {
  const handleChange = (e) => {
    const comment = e.target.value;
    onChange(comment);
  };

  return (
    <div className="mb-3">
      <input
        type="text"
        id="adjustComment"
        onChange={handleChange}
        className="form-control"
        placeholder="หมายเหตุ การปรับสต็อก"
        required
        style={{ maxWidth: '500px', width: '100%' }}
      />
    </div>
  );
}

export default AdjBillToAddForm;