// src/utils/apiClient.js
import axios from 'axios';

// Function to get the API key from localStorage
const getApiKey = () => localStorage.getItem('api_key');

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Set your base URL here
  headers: {
    'X-API-KEY': getApiKey(),
    'Content-Type': 'application/json', // Set default Content-Type if needed
  },
});

// Request interceptor to update the API key dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const apiKey = getApiKey();
    if (apiKey) {
      config.headers['X-API-KEY'] = apiKey;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Optional: Response interceptor for global error handling or logging
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    // For example, you can log out the user if a 401 Unauthorized error occurs
    if (error.response && error.response.status === 401) {
      // Perform logout or redirect to login page
      // Example:
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;