// src/utils/AddOrEditModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function AddOrEditModal({
  show,
  onHide,
  fields,
  itemName,
  itemData,
  setItemData,
  onSave,
  saving,
  error,
  mode, // 'create' or 'edit'
}) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'edit' ? 'แก้ไข' : 'สร้าง'} {itemName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fields.map((field) => (
          <div className="form-group" key={field.key}>
            <label htmlFor={`${mode}_${field.key}`}>{field.label}</label>
            <input
              type="text"
              className="form-control"
              id={`${mode}_${field.key}`}
              value={itemData[field.key]}
              onChange={(e) => setItemData({ ...itemData, [field.key]: e.target.value })}
            />
          </div>
        ))}
        {error && <div className="alert alert-danger mt-2">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSave} disabled={saving}>
          {saving ? (mode === 'edit' ? 'Editing...' : 'Creating...') : mode === 'edit' ? 'Edit' : 'Create'}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddOrEditModal;