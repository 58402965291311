// src/components/sell_page/SellBillForm.js
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import CustomerTable from '../main_sql/CustomerTable'; // Import CustomerTable
import { getToday } from '../../utils/dateFunc'; // Import CustomerTable

function SellBillForm({ onChange }) {
  const [formData, setFormData] = useState({
    sellComment: localStorage.getItem('sellComment') || '',
    sellDate: localStorage.getItem('sellDate') || getToday(),
    customer: localStorage.getItem('customer') || '',
    customerId: localStorage.getItem('customerId') || '',
  });
  const [showCustomerModal, setShowCustomerModal] = useState(false); // State to control the modal visibility

  // Set the default sellDate to today's date on component mount
  useEffect(() => {
    const todayDate = getToday();
    const updatedData = {
      ...formData,
      sellDate: todayDate,
    };
    setFormData(updatedData);
    onChange(updatedData); // Pass the updated data to the parent component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { [name]: value };
    setFormData((prevData) => ({ ...prevData, ...updatedData }));
    localStorage.setItem(name, value); // Save the value to localStorage
    onChange(updatedData); // Pass only the updated field
  };
  // Open the customer selection modal
  const handleCustomerClick = () => {
    setShowCustomerModal(true);
  };

  // Handle customer selection from the modal
  const handleCustomerSelect = (customer) => {
    const updatedData = {
      customer: customer.nickname,
      customerId: customer.idcustomer,
    };
    setFormData((prevData) => ({ ...prevData, ...updatedData }));
    localStorage.setItem('customer', customer.nickname); // Save customer name to localStorage
    localStorage.setItem('customerId', customer.idcustomer); // Save customer ID to localStorage
    onChange(updatedData);
    setShowCustomerModal(false); // Close the modal
  };

  return (
    <div className="mb-3">
      <div className="row">
      <div className="col-auto">
      <input
        type="date"
        id="sellDate"
        name="sellDate"
        value={formData.sellDate}
        onChange={handleChange}
        className="form-control"
        placeholder="วันที่ขาย"
        required
        style={{ maxWidth: '150px', width: '100%', marginBottom: '10px', height: '40px' }}
      />
      </div>
      <input
        type="text"
        id="customer"
        name="customer"
        value={formData.customer}
        readOnly
        onClick={handleCustomerClick}
        onChange={handleChange}
        className="form-control"
        placeholder="ชื่อลูกค้า"
        style={{ maxWidth: '200px', width: '100%' , marginBottom: '10px', marginLeft: '0px', height: '40px'  }}
      />
      <div className="col">
      <input
        type="text"
        id="sellComment"
        name="sellComment"
        value={formData.sellComment}
        onChange={handleChange}
        className="form-control"
        placeholder="หมายเหตุบิล"
        style={{ maxWidth: '400px', width: '100%', height: '40px', textAlign: 'left'}}
      />
      </div>
      </div>
      {/* Customer selection modal */}
      <Modal show={showCustomerModal} onHide={() => setShowCustomerModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerTable onSelect={handleCustomerSelect} /> {/* Pass the handleCustomerSelect function */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowCustomerModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      </div>
  );
}

export default SellBillForm;