import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../utils/apiClient';
import PaginationComponent from '../Pagination';
import { Modal, Button } from 'react-bootstrap';

function SellJobTable({ sellJobData, fetchSellJobData ,selectedServiceSellId}) {
  const [data, setData] = useState([]);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedRowId, setSelectedRowId] = useState(null);

  // State for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // Initialize data from sellJobData when the component mounts
  useEffect(() => {
    if (sellJobData) {
      const fetchedData = sellJobData.map((item) => ({
        ...item,
        qty: Number(item.servjobqtytoadd) || 0,
        price: Number(item.servjobpricetoadd) || 0,
        discount: Number(item.servjobdisctoadd) || 0,
        comment: item.servjobcommenttoadd || '',
      }));
      setData(fetchedData);
    }
  }, [sellJobData]);

  // Memoize sortedData to prevent unnecessary recalculations
  const sortedData = useMemo(() => {
    let sorted = [...data];
    if (sortColumn) {
      sorted.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        // Handle undefined values
        if (aValue === undefined || aValue === null) return 1;
        if (bValue === undefined || bValue === null) return -1;

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [data, sortColumn, sortDirection]);

  // Handle sorting
  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortColumn === columnKey && sortDirection === 'asc') {
      direction = 'desc';
    }
    setSortColumn(columnKey);
    setSortDirection(direction);
  };

  // Open the delete confirmation modal
  const openDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  // Close the delete confirmation modal
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedRow(null);
  };

  // Handle row click to change the background color
  const handleRowClick = (idservjobtoadd) => {
    setSelectedRowId(idservjobtoadd);
  };

  // Log the selectedRowId whenever it changes
  useEffect(() => {
    console.log('Selected Row ID:', selectedRowId);
  }, [selectedRowId]);

  function formatNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  function removeCommas(value) {
    return value.replace(/,/g, '');
  }

  const handleFormattedInputChange = (id, field, value) => {
    const numericValue = removeCommas(value);
    handleInputChange(id, field, numericValue);
  };

  const handleInputChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.idservjobtoadd === id
          ? {
              ...row,
              [field]:
                field === 'qty' || field === 'price' || field === 'discount'
                  ? Number(value) || 0
                  : value,
            }
          : row
      )
    );
  };

  const totalSum = useMemo(() => {
    return data.reduce((acc, row) => acc + ((row.price - row.discount) * row.qty), 0);
  }, [data]);

  const handleBlur = (id) => {
    handleSave(id); // Save the numeric value
  };

  const handleSave = async (id) => {
    try {
      const updatedRow = data.find((row) => row.idservjobtoadd === id);
      const payload = {
        servjobpricetoadd: updatedRow.price,
        servjobdisctoadd: updatedRow.discount,
        servjobqtytoadd: updatedRow.qty,
        servjobcommenttoadd: updatedRow.comment,
      };
      await axiosInstance.patch(`/Servjobtoaddsql/${id}/`, payload);
        // Fetch updated data
      fetchSellJobData(); 
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  // Define the function to render table rows for the PaginationComponent
  const renderTableRows = (currentRows) => (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th onClick={() => handleSort('servicename')}>
            ชื่อบริการ {sortColumn === 'servicename' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('servjobcommenttoadd')}>
            หมายเหตุ {sortColumn === 'servjobcommenttoadd' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('qty')}>
            จำนวน {sortColumn === 'qty' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('price')}>
            ราคา {sortColumn === 'price' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('discount')}>
            ส่วนลด {sortColumn === 'discount' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('sum')}>
            รวม {sortColumn === 'sum' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th style={{ width: '10px' }}></th>
        </tr>
      </thead>
      <tbody>
        {currentRows.length > 0 ? (
          currentRows.map((row) => (
            <tr
              key={row.idservjobtoadd}
              className={selectedRowId === row.idservjobtoadd ? 'selected-row' : ''}
              onClick={() => handleRowClick(row.idservjobtoadd)}
            >
              <td>{row.servicename}</td>
              {/* comment Column */}
              <td>
                <input
                  type="text"
                  value={row.comment}
                  onChange={(e) => handleInputChange(row.idservjobtoadd, 'comment', e.target.value)}
                  onBlur={() => handleSave(row.idservjobtoadd)}
                  style={{
                    width: `${((row.comment || '').length * 0.8)+2 }ch`,
                    height: '32px',
                  }}
                />
              </td>
              {/* Quantity Column */}
              <td style={{ textAlign: 'right' }}>
                <input
                  type="text"
                  value={formatNumberWithCommas(row.qty || 0)}
                  onChange={(e) => handleFormattedInputChange(row.idservjobtoadd, 'qty', e.target.value)}
                  onFocus={(e) => (e.target.value = row.qty || '')}
                  onBlur={(e) => {
                    e.target.value = formatNumberWithCommas(row.qty || 0);
                    handleBlur(row.idservjobtoadd);
                  }}
                  style={{
                    width: `${(row.qty || 0).toString().length + 2.5}ch`,
                    height: '32px',
                    textAlign: 'right',
                  }}
                />
              </td>
              {/* Price Column */}
              <td style={{ textAlign: 'right' }}>
                <input
                  type="text"
                  value={formatNumberWithCommas(row.price || 0)}
                  onChange={(e) => handleFormattedInputChange(row.idservjobtoadd, 'price', e.target.value)}
                  onFocus={(e) => (e.target.value = row.price|| '')}
                  onBlur={(e) => {
                    e.target.value = formatNumberWithCommas(row.price || 0);
                    handleBlur(row.idservjobtoadd);
                  }}
                  style={{
                    width: `${(row.price || 0).toString().length + 2.5}ch`,
                    height: '32px',
                    textAlign: 'right',
                  }}
                />
              </td>
              {/* Discount Column */}
              <td style={{ textAlign: 'right' }}>
                <input
                  type="text"
                  value={formatNumberWithCommas(row.discount || 0)}
                  onChange={(e) => handleFormattedInputChange(row.idservjobtoadd, 'discount', e.target.value)}
                  onFocus={(e) => (e.target.value = row.discount || '')}
                  onBlur={(e) => {
                    e.target.value = formatNumberWithCommas(row.discount || 0);
                    handleBlur(row.idservjobtoadd);
                  }}
                  style={{
                    width: `${(row.discount || 0).toString().length + 2.5}ch`,
                    height: '32px',
                    textAlign: 'right',
                  }}
                />
              </td>
              {/* Sum Column */}
              <td style={{ textAlign: 'right', paddingTop: '12px' }}>
                {formatNumberWithCommas((row.price - row.discount) * row.qty)}
              </td>
              <td>
                <span
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click from triggering row selection
                    openDeleteModal(row);
                  }}
                >
                  ลบ
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7" style={{ textAlign: 'center' }}>
              No Job Data Found
            </td>
          </tr>
        )}
      </tbody>
      {/* Table Footer for Total Sum */}
      <tfoot>
        <tr>
          <td colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold' }}>
            รวม
          </td>
          <td style={{ textAlign: 'right' }}>{formatNumberWithCommas(totalSum)}</td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  );

  // Function to handle row deletion
  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await axiosInstance.delete(`/Servjobtoaddsql/${selectedRow.idservjobtoadd}/`);
        setShowDeleteModal(false); // Close the modal after deletion
        setSelectedRow(null); // Reset selected row
        // Fetch updated data
        fetchSellJobData(); // Use the prop passed from SellPage
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }
  };

  return (
    <div>
      <PaginationComponent
        data={sortedData}
        rowsPerPage={rowsPerPage}
        renderTableRows={renderTableRows}
      />
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <p>
              Are you sure you want to delete the job: {selectedRow.servicename}?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SellJobTable;