import React, { useState, useEffect } from 'react';
import { useZxing } from 'react-zxing';
import './BarcodeScanner.css';

const BarcodeScanner = ({ onBarcodeScanned }) => {
  const [scanResult, setScanResult] = useState('');
  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState('');

  // Constraints for better focus and resolution
  const constraints = {
    video: {
      facingMode: { ideal: 'environment' },
      width: { ideal: 4096 },
      height: { ideal: 4096 },
      focusMode: 'continuous',
    },
  };

  // ZXing hook for barcode scanning
  const { ref, torch: { on, off, isOn, isAvailable } } = useZxing({
    constraints,
    deviceId: selectedDeviceId,
    onDecodeResult(result) {
      const barcodeData = result.getText();
      setScanResult(barcodeData);
      onBarcodeScanned(barcodeData);
    },
    timeBetweenDecodingAttempts: 100,
  });

  // Fetch available video devices (cameras)
  useEffect(() => {
    (async () => {
      try {
        const availableDevices = await navigator.mediaDevices.enumerateDevices();
        const availableVideoDevices = availableDevices.filter(device => device.kind === 'videoinput');
        setDevices(availableVideoDevices);

        const savedDeviceId = localStorage.getItem('selectedDeviceId');
        if (savedDeviceId && availableVideoDevices.some(device => device.deviceId === savedDeviceId)) {
          setSelectedDeviceId(savedDeviceId);
          console.log('Retrieved deviceId from localStorage:', savedDeviceId);
        } else if (availableVideoDevices.length > 0) {
          setSelectedDeviceId(availableVideoDevices[0].deviceId);
        }
      } catch (error) {
        console.error('Error fetching cameras:', error);
      }
    })();
  }, []);

  // Update localStorage when selectedDeviceId changes
  useEffect(() => {
    if (selectedDeviceId) {
      localStorage.setItem('selectedDeviceId', selectedDeviceId);
      console.log('Selected deviceId saved to localStorage:', selectedDeviceId);
    }
  }, [selectedDeviceId]);

  // Handle video click to remount camera by toggling selectedDeviceId
  const handleVideoClick = () => {
    if (selectedDeviceId) {
      // Temporarily clear selectedDeviceId to refresh focus
      setSelectedDeviceId('');
      setTimeout(() => {
        setSelectedDeviceId(localStorage.getItem('selectedDeviceId'));
        console.log('Camera refocused');
      }, 100); // Small delay to simulate toggling
    }
  };

  return (
    <div className="scanner-container">
      <h2>Barcode Scanner</h2>

      {/* Camera selection dropdown */}
      <label htmlFor="camera-select">Select Camera: </label>
      <select
        id="camera-select"
        onChange={(e) => setSelectedDeviceId(e.target.value)}
        value={selectedDeviceId}
      >
        {devices.map(device => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${device.deviceId}`}
          </option>
        ))}
      </select>

      {/* Video feed with overlay */}
      <div className="video-container" onClick={handleVideoClick}>
        <video ref={ref} className="scanner-video" />
        <div className="overlay-line"></div>
      </div>

      {/* Torch control */}
      {isAvailable && (
        <button onClick={() => (isOn ? off() : on())}>
          {isOn ? 'Turn off Torch' : 'Turn on Torch'}
        </button>
      )}

      {/* Display scan result */}
      {scanResult && (
        <div>
          <h3>Scanned Result:</h3>
          <p>{scanResult}</p>
        </div>
      )}
    </div>
  );
};

export default BarcodeScanner;