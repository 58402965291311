import React, { useState } from 'react';
import './FilterForm.css';
import { Modal } from 'react-bootstrap';
import BarcodeScanner from './BarcodeScanner'; // Import your BarcodeScanner component

function FilterForm({ filters, handleFilterChange, handleClearFilters, seeAll, handleSeeAllChange}) {
  const [showScanner, setShowScanner] = useState(false); // State to handle modal visibility

  const handleBarcodeDoubleClick = () => {
    setShowScanner(true); // Open the modal when double-clicking on the barcode input
  };

  const handleBarcodeScanned = (scannedBarcode) => {
    handleFilterChange({
      target: {
        name: 'barcode',
        value: scannedBarcode, // Update barcode input with scanned data
      },
    });
    setShowScanner(false); // Close the scanner modal
  };

  return (
    <>
      <form className="mb-3">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ชื่อสินค้า"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ประเภท"
              name="categoryname"
              value={filters.categoryname}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="กลุ่ม"
              name="typename"
              value={filters.typename}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ยี่ห้อ"
              name="brandname"
              value={filters.brandname}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="รุ่น"
              name="modelname"
              value={filters.modelname}
              onChange={handleFilterChange}
            />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="ไซส์"
              name="sizename"
              value={filters.sizename}
              onChange={handleFilterChange}
            />
          </div>
          <div className="col">    
              <input
                type="text"
                className="form-control mb-2"
                placeholder="สี"
                name="colorname"
                value={filters.colorname}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                className="form-control mb-2"
                placeholder="ผู้นำเข้า"
                name="suppname"
                value={filters.suppname}
                onChange={handleFilterChange}
              />
            <input
              type="text"
              className="form-control mb-2"
              placeholder="บาร์โค้ด"
              name="barcode"
              value={filters.barcode}
              onChange={handleFilterChange}
              onDoubleClick={handleBarcodeDoubleClick} // Trigger modal on double-click
            />
              <div className="d-flex justify-content-between align-items-center button-group">
                <button type="button" className="clear-button" onClick={handleClearFilters}>
                  <span>Clear</span>
                </button>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="seeAll"
                    checked={seeAll}
                    onChange={handleSeeAllChange}
                  />
                  <label className="form-check-label" htmlFor="seeAll">All</label>
                </div>
              </div>
          </div>
        </div>
      </form>

      {/* Modal for barcode scanner */}
      <Modal show={showScanner} onHide={() => setShowScanner(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Barcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BarcodeScanner onBarcodeScanned={handleBarcodeScanned} /> {/* Pass callback */}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FilterForm;