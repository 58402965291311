//src/components/service_page/FilterService.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap for grid layout

function FilterService({ onFilter, jobStatusOptions, jobCategoryOptions, onAddService,selectedService ,onSendtoSell }) {
  const [customer, setCustomer] = useState(localStorage.getItem('filter_customer') || '');
  const [selectedStatus, setSelectedStatus] = useState(localStorage.getItem('filter_status') || 'not_completed'); // Default to "not_completed"
  const [showAll, setShowAll] = useState(localStorage.getItem('filter_showAll') === 'true'); // Convert stored string to boolean
  const [selectedJobCategory, setSelectedJobCategory] = useState(localStorage.getItem('filter_jobCategory') || 'หน้าร้าน'); // Default job category

  // Trigger filtering whenever the customer input or selected status changes
  useEffect(() => {
    onFilter({ customer, selectedStatus, showAll, selectedJobCategory });
  }, [customer, selectedStatus, showAll, selectedJobCategory, onFilter]);

  // Store filter values in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('filter_customer', customer);
    localStorage.setItem('filter_status', selectedStatus);
    localStorage.setItem('filter_showAll', showAll);
    localStorage.setItem('filter_jobCategory', selectedJobCategory);
  }, [customer, selectedStatus, showAll, selectedJobCategory]);

  // Handle the "Clear" button to reset the filters
  const handleClear = () => {
    setCustomer('');
    setSelectedStatus('not_completed'); // Reset to default status
    setShowAll(false); // Uncheck the "All" checkbox
    setSelectedJobCategory('งาน service หน้าร้าน'); // Reset to default job category
    onFilter({ customer: '', selectedStatus: 'not_completed', showAll: false, selectedJobCategory: 'งาน service หน้าร้าน' }); // Reset the filter

    // Clear localStorage values
    localStorage.removeItem('filter_customer');
    localStorage.removeItem('filter_status');
    localStorage.removeItem('filter_showAll');
    localStorage.removeItem('filter_jobCategory');
  };

  return (
    <form className="mb-3">
      <div className="row g-0">
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '250px', alignItems: 'flex-end', justifyContent: 'center', height: '100%',marginRight: '6px' }}>
      <div className="form-group mb-2" style={{ display: 'flex', alignItems: 'center',justifyContent: 'center' ,maxWidth: '300px'}}>
        <label htmlFor="statusDropdown" className="me-2 mb-0">สถานะ : </label>
            <select
              id="statusDropdown"
              className="form-select me-3 custom-select-border"
              style={{ width: '150px', height: '40px' }}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="not_completed">ที่ยังไม่จบงาน</option> {/* Default filter */}
              {jobStatusOptions.map((status, index) => (
                <option key={index} value={status}>{status}</option>
              ))}
            </select>
      </div>

      <div className="form-group" style={{ display: 'flex', alignItems: 'center',justifyContent: 'center', maxWidth: '300px'}}>
      <label htmlFor="jobCategoryDropdown" className="me-2 mb-0">งาน : </label>
          <select
            id="jobCategoryDropdown"
            className="form-select me-3 custom-select-border"
            style={{ width: '150px', height: '40px' }}
            value={selectedJobCategory}
            onChange={(e) => setSelectedJobCategory(e.target.value)}
          >
            <option value="">All</option>
            {jobCategoryOptions.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
      </div>
      </div>

        {/* Customer Filter */}
        <div className="col-6 me-0">
          <div className="form-group d-flex align-items-center">
            <input
              type="text"
              id="customer"
              placeholder="ลูกค้า"
              className="form-control ms-0"
              style={{ width: '150px', height: '40px'}}
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
            />
          </div>
        </div>

          {/* Add the Select Columns button */}
          <div className="col-12 d-flex justify-content-between mt-2">
            <div>
              <button type="button" className="btn btn-primary me-2" onClick={onAddService}>
                เพิ่มงานที่ทำ
              </button>
              <button type="button" className="btn btn-success me-2" onClick={onSendtoSell}>
                ออกบิล
              </button>
            </div>
            {/* Right-aligned buttons */}
              <div className="d-flex align-items-center">
                <button type="button" className="btn btn-secondary me-2" onClick={handleClear}>
                  เคลียร์
                </button>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="allCheck"
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                  />
                  <label className="form-check-label" htmlFor="allCheck">All</label>
                </div>
              </div>
          </div>
        </div>
    </form>
  );
}

export default FilterService;