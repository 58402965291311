// src/components/service_page/service_page/service_sql/JobCategoryTable.js
import React, { useEffect } from 'react';
import GenericSqlTable from '../../GenericSqlTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';

function JobCategoryTable({ onSelect }) {
  const dispatch = useDispatch();
  
  // Retrieve selected columns from Redux for the "jobCategory" page
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'jobCategory'));

  // Define the columns available for this table
  const columns = [
    { key: 'idjobcat', label: 'ID' },
    { key: 'jobcatgroup', label: 'Job Category' },
  ];

  // On initial render, set the columns if not already set in the Redux state
  useEffect(() => {
    if (selectedColumns.length === 0) {
      dispatch(setColumns({ page: 'jobCategory', columns: columns.map((col) => col.key) }));
    }
  }, [dispatch, selectedColumns, columns]);

  // Handle column changes
  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'jobCategory', columns: newSelectedColumns }));
  };

  return (
    <GenericSqlTable
      apiUrl={`${process.env.REACT_APP_API_BASE_URL}/Jobcatnamesql/`} // Replace with your actual endpoint
      title="หมวดหมู่งาน" // Adjust the title as needed
      onSelect={onSelect}
      filterKey="jobcatcancle" // Replace with the actual field indicating cancellation
      idKey="idjobcat"        // Replace with the actual ID field in your data
      nameKey="jobcatgroup"    // Replace with the actual name field in your data
      columns={columns}
      selectedColumns={selectedColumns}
      onColumnsChange={handleColumnsChange}
      searchKey="jobcatgroup" // Use the appropriate key for searching
    />
  );
}

export default JobCategoryTable;