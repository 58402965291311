// src/components/adjust_page/AdjProdToAddTable.js

import React, { useEffect, useState, useMemo , useCallback} from 'react';
import axiosInstance from '../../utils/apiClient';
import PaginationComponent from '../Pagination';
import FilteradjTable from './FilteradjTable';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button components
import TableButton from '../../utils/TableButton'; // Import the TableButton component


function AdjProdToAddTable({ adjProdData }) {
  const [data, setData] = useState([]);
  const rowsPerPage = 10;
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  // State for filters
  const [filters, setFilters] = useState({
    catname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    barcode: '',
  });

  // State for delete confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // Update data whenever adjProdData changes
  useEffect(() => {
    const fetchedData = adjProdData.map((item) => ({
      ...item,
      prodcount: item.prodcount !== null ? Number(item.prodcount) : 0,
    }));
    setData(fetchedData);
  }, [adjProdData]);

  // Handle filter changes
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({
      catname: '',
      typename: '',
      brandname: '',
      modelname: '',
      sizename: '',
      colorname: '',
      barcode: '',
    });
  };

  const handleRefresh = () => {
    // If you need to refetch data, you can call a function here
  };

  // Memoize filteredData to prevent unnecessary recalculations
  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchesFilters =
        (item.catname || '').toLowerCase().includes(filters.catname.toLowerCase()) &&
        (item.typename || '').toLowerCase().includes(filters.typename.toLowerCase()) &&
        (item.brandname || '').toLowerCase().includes(filters.brandname.toLowerCase()) &&
        (item.modelname || '').toLowerCase().includes(filters.modelname.toLowerCase()) &&
        (item.sizename || '').toLowerCase().includes(filters.sizename.toLowerCase()) &&
        (item.colorname || '').toLowerCase().includes(filters.colorname.toLowerCase()) &&
        (item.prodbarcode || '').toLowerCase().includes(filters.barcode.toLowerCase());
      return matchesFilters;
    });
  }, [data, filters]);

  // Memoize sortedData to prevent unnecessary recalculations
  const sortedData = useMemo(() => {
    let sorted = [...filteredData];
    if (sortColumn) {
      sorted.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        // Handle undefined values
        if (aValue === undefined || aValue === null) return 1;
        if (bValue === undefined || bValue === null) return -1;

        if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sorted;
  }, [filteredData, sortColumn, sortDirection]);

  // Handle sorting
  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortColumn === columnKey && sortDirection === 'asc') {
      direction = 'desc';
    }
    setSortColumn(columnKey);
    setSortDirection(direction);
  };

  // Handle input change for editable cells
  const handleInputChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, [field]: field === 'prodcount' ? Number(value) || 0 : value } : row
      )
    );
  };

  // Function to handle saving data to the backend
  const handleSave = async (id) => {
    try {
      const updatedRow = data.find((row) => row.id === id);
      await axiosInstance.patch(`/adjprodtoadd/${id}/`, updatedRow);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  // Function to handle row deletion
  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await axiosInstance.delete(`/adjprodtoadd/${selectedRow.id}/`);
        setData((prevData) => prevData.filter((row) => row.id !== selectedRow.id));
        setShowDeleteModal(false); // Close the modal after deletion
        setSelectedRow(null); // Reset selected row
      } catch (error) {
        console.error('Error deleting row:', error);
      }
    }
  };

  // Open the delete confirmation modal
  const openDeleteModal = (row) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  // Close the delete confirmation modal
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedRow(null);
  };

  const handleClearAll = useCallback(async () => {
    const confirmation = window.confirm('Are you sure you want to delete all rows?');
    if (confirmation) {
      try {
        await axiosInstance.post('/adjprodtoadd/delete-all/');
        setData([]); // Clear the data in the table
      } catch (error) {
        console.error('Error clearing all rows:', error);
      }
    }
  }, [setData]); // Add setData as a dependency because it's being used in the function.

  // Define the function to render table rows for the PaginationComponent
  const renderTableRows = (currentRows) => (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th onClick={() => handleSort('id')}>
            ID {sortColumn === 'id' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('catname')}>
            กลุ่ม {sortColumn === 'catname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('typename')}>
            ย่อย {sortColumn === 'typename' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('brandname')}>
            ยี่ห้อ {sortColumn === 'brandname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('modelname')}>
            รุ่น {sortColumn === 'modelname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('sizename')}>
            ไซส์ {sortColumn === 'sizename' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('colorname')}>
            สี {sortColumn === 'colorname' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('oldstock')}>
            st {sortColumn === 'oldstock' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th onClick={() => handleSort('prodcount')}>
            นับได้ {sortColumn === 'prodcount' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th>หมายเหตุ</th>
          <th onClick={() => handleSort('prodbarcode')}>
            BC {sortColumn === 'prodbarcode' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {currentRows.map((row) => (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>{row.catname}</td>
            <td>{row.typename}</td>
            <td>{row.brandname}</td>
            <td>{row.modelname}</td>
            <td>{row.sizename}</td>
            <td>{row.colorname}</td>
            <td>{row.oldstock}</td>
            <td>
              <input
                type="number"
                value={row.prodcount ?? 0}
                onChange={(e) => handleInputChange(row.id, 'prodcount', e.target.value)}
                onBlur={() => handleSave(row.id)}
                style={{ width: '5ch' }}
              />
            </td>
            <td>
              <input
                type="text"
                value={row.adjprodcomment ?? ''}
                onChange={(e) => handleInputChange(row.id, 'adjprodcomment', e.target.value)}
                onBlur={() => handleSave(row.id)}
                style={{ width: '100%' }}
              />
            </td>
            <td>{row.prodbarcode}</td>
            <td>
              <span
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => openDeleteModal(row)}
              >
                ลบ
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <FilteradjTable
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleClearFilters={handleClearFilters}
        handleRefresh={handleRefresh}
      />
      <PaginationComponent
        data={sortedData}
        rowsPerPage={rowsPerPage}
        renderTableRows={renderTableRows}
        handleClearAll={handleClearAll}
      >
      <TableButton
          label="Clear All"
          color="danger" // This will render a red button
          onClick={handleClearAll}
        />
      </PaginationComponent>
      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>ยืนยันการลบ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <p>
              คุณต้องการลบสินค้า: {selectedRow.catname} {selectedRow.typename}{' '}
              {selectedRow.brandname} {selectedRow.modelname} {selectedRow.sizename} {' '}
              {selectedRow.colorname} ใช่หรือไม่?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            ยกเลิก
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            ลบ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdjProdToAddTable;