// src/components/service_page/ServiceJobAddF.js
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import JobServiceNameTable from './service_sql/JobServiceNameTable'; // Import JobServiceNameTable

function ServiceJobAddModal({ servsellid, job, onSave, onCancel, show, handleConfirm }) {
  // Initialize form state
  const [formData, setFormData] = useState({
    servjobname: 'Full Service', // Default job name
    idservicename: 1, // Default job service ID
    servjobcomment: '',
    servjobprice: 0,
    servjobdisc: 0,
    servjobqty: 1,
  });

  const [errors, setErrors] = useState({}); // State to store validation errors

  const [showJobServiceNameTable, setShowJobServiceNameTable] = useState(false); // State to manage JobServiceNameTable visibility
  
  useEffect(() => {
    if (job) {
      setFormData({
        servjobname: job.servicename || 'Full Service',
        idservicename: job.idservicename || '',
        servjobcomment: job.servjobcomment || '',
        servjobprice: job.servjobprice ? job.servjobprice.toString() : '0',
        servjobdisc: job.servjobdisc ? job.servjobdisc.toString() : '0',
        servjobqty: job.servjobqty ? job.servjobqty.toString() : '1',
      });
    } else {
      // Reset formData to default values when job is not provided
      setFormData({
        servjobname: '',
        idservicename: '',
        servjobcomment: '',
        servjobprice: '0',
        servjobdisc: '0',
        servjobqty: '1',
      });
    }
  }, [job]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'servjobprice' || name === 'servjobdisc' || name === 'servjobqty') {
      if (value === '' || /^[0-9\b]+$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Validate form data
  const validate = () => {
    const newErrors = {};
    if (!formData.servjobname) newErrors.servjobname = 'Job name is required.';
    if (formData.servjobprice === '' || isNaN(formData.servjobprice)) {
      newErrors.servjobprice = 'Price is required and must be a number.';
    } else if (Number(formData.servjobprice) < 0) {
      newErrors.servjobprice = 'Price cannot be negative.';
    }

    if (formData.servjobdisc === '' || isNaN(formData.servjobdisc)) {
      newErrors.servjobdisc = 'Discount must be a number.';
    } else if (Number(formData.servjobdisc) < 0) {
      newErrors.servjobdisc = 'Discount cannot be negative.';
    } else if (Number(formData.servjobdisc) > Number(formData.servjobprice)) {
      newErrors.servjobdisc = 'Discount cannot be greater than price.';
    }

    if (formData.servjobqty === '' || isNaN(formData.servjobqty)) {
      newErrors.servjobqty = 'Quantity is required and must be a number.';
    } else if (Number(formData.servjobqty) <= 0) {
      newErrors.servjobqty = 'Quantity must be greater than zero.';
    } else if (!Number.isInteger(Number(formData.servjobqty))) {
      newErrors.servjobqty = 'Quantity must be an integer.';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      // Do not proceed
      return;
    }
    // If no errors, proceed
    await handleConfirm(formData, servsellid, job);
    // Call onSave to close the modal and refresh data
    onSave();
    // Reset formData to desired default values
    setFormData({
      servjobname: '',
      idservicename: '',
      servjobcomment: '',
      servjobprice: '0',
      servjobdisc: '0',
      servjobqty: '1',
    });
    // Reset errors
    setErrors({});
  };


  // Handle job service name selection
  const handleJobServiceNameSelect = (jobService) => {
    setFormData({
      ...formData,
      servjobname: jobService.servicename,
      servjobprice: jobService.serviceprice ?? 0, // Set job price
      idservicename: jobService.idservicename
    });
    setShowJobServiceNameTable(false);
  };

    // Handle focus event to clear '0' when field is focused
    const handleFocus = (e) => {
      const { name } = e.target;
      if ((name === 'servjobprice' || name === 'servjobdisc' || name === 'servjobqty') && formData[name] === '0') {
        setFormData({
          ...formData,
          [name]: '',
        });
      }
    };

  const handleBlur = (e) => {
    const { name } = e.target;
    if ((name === 'servjobprice' || name === 'servjobdisc' || name === 'servjobqty') && (formData[name] === '' || formData[name] == null)) {
      setFormData({
        ...formData,
        [name]: '0',
      });
    }
  };
  
  return (
    <Modal show={show} onHide={onCancel} dialogClassName="custom-modal-width400">
      <Modal.Header closeButton>
        <Modal.Title>เพิ่มงานที่ทำ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit}>
      <div className="d-flex form-group align-items-center mb-3">
        <label className="d-flex flex-row-reverse" style={{ marginRight: '5px',maxWidth: '70px', width: '100%' }} htmlFor="servjobname"> งานที่ทำ: </label>
        <div style={{ maxWidth: '180px', width: '100%' }}>
          <input
            type="text"
            className={`form-control ${errors.servjobname ? 'is-invalid' : ''}`}
            id="servjobname"
            name="servjobname"
            value={formData.servjobname}
            readOnly
            onClick={() => setShowJobServiceNameTable(true)} // Open JobServiceNameTable on click
            style={{ height: '40px', cursor: 'pointer' }}
          />
          {errors.servjobname && (
            <div className="invalid-feedback">
              {errors.servjobname}
            </div>
          )}
        </div>
        </div>
        {/* Conditionally render JobServiceNameTable or the rest of the form */}
        {showJobServiceNameTable ? (
          <JobServiceNameTable
            onSelect={handleJobServiceNameSelect} // Handle job service name selection
            onClose={() => setShowJobServiceNameTable(false)} // Handle close action
          />
        ) : (
          <>
            <div className="d-flex form-group align-items-center mb-3">
            <label className="d-flex flex-row-reverse" style={{ marginRight: '5px',maxWidth: '70px', width: '100%' }} htmlFor="servjobname">หมายเหตุ:</label>
              <input
                type="text"
                className="form-control"
                id="servjobcomment"
                name="servjobcomment"
                value={formData.servjobcomment}
                onChange={handleChange}
                style={{ maxWidth: '180px', width: '100%', height: '40px', cursor: 'pointer' }}
              />
            </div>
            <div className="d-flex form-group align-items-center mb-3">
            <label className="d-flex flex-row-reverse" style={{ marginRight: '5px',maxWidth: '70px', width: '100%' }} htmlFor="servjobname">ค่าแรง:</label>
              <input
                type="text"
                className="form-control"
                id="servjobprice"
                name="servjobprice"
                value={formData.servjobprice}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ maxWidth: '180px', width: '100%', height: '40px', cursor: 'pointer' }}
              />
              {errors.servjobprice && (
              <div className="invalid-feedback">
                {errors.servjobprice}
              </div>
            )}
            </div>
            <div className="d-flex form-group align-items-center mb-3">
            <label className="d-flex flex-row-reverse" style={{ marginRight: '5px',maxWidth: '70px', width: '100%' }} htmlFor="servjobdisc">ส่วนลด</label>
              <input
                type="text"
                className="form-control"
                id="servjobdisc"
                name="servjobdisc"
                value={formData.servjobdisc}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ maxWidth: '180px', width: '100%', height: '40px', cursor: 'pointer' }}
              />
              {errors.servjobdisc && (
              <div className="invalid-feedback">
                {errors.servjobdisc}
              </div>
            )}
            </div>
            <div className="d-flex form-group align-items-center mb-3">
            <label className="d-flex flex-row-reverse" style={{ marginRight: '5px',maxWidth: '70px', width: '100%' }} htmlFor="servjobqty">จำนวน</label>
              <input
                type="text"
                className="form-control"
                id="servjobqty"
                name="servjobqty"
                value={formData.servjobqty}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ maxWidth: '180px', width: '100%', height: '40px', cursor: 'pointer' }}
              />
              {errors.servjobqty && (
              <div className="invalid-feedback">
                {errors.servjobqty}
              </div>
            )}
            </div>
        </>
        )}
      </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Create</Button>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceJobAddModal;