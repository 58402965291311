// src/components/service_page/service_sql/JobServiceNameTable.js
import React, { useState, useEffect, useRef } from 'react';
import CustomTable from '../../../utils/CustomTable'; // Adjust the path as needed
import axiosInstance from '../../../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';

function JobServiceName({ onSelect, onClose }) {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');

  // Create a ref for the search input
  const searchInputRef = useRef(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/Servicenamesql/');
        const activeItems = response.data.filter(item => !item.servicenamecancel);
        setData(activeItems);
      } catch (error) {
        console.error('Error fetching Service name data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  // Focus the search input when the component mounts
  useEffect(() => {
    if (!loading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  // Handle search input changes
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Define columns
  const columns = [
    { key: 'idservicename', label: 'ID' },
    { key: 'servicename', label: 'Category', className: 'text-center' },
    { key: 'serviceprice', 
      label: 'Price', 
      className: 'text-end',
      render: (item) => (item.serviceprice ? item.serviceprice.toLocaleString() : ''),},
  ];

  // All columns are selected; we do not pass onColumnsChange to hide the "Select Columns" button
  const selectedColumns = columns.map(col => col.key);

  // Filter data based on search input
  const filteredData = data.filter(item =>
    item.servicename.toLowerCase().includes(filter.toLowerCase())
  );

  // Handle row clicks
  const handleRowClick = (servicename) => {
    if (onSelect) {
      onSelect(servicename);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center">
      <h2>งานเซอร์วิซ</h2>
      <span
          className="text-danger"
          style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
          onClick={onClose}
          aria-label="Close"
          role="button"
          >
          ×
        </span>
        </div>

      <div className="mb-3">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Jod Name"
              value={filter}
              onChange={handleFilterChange}
              ref={searchInputRef}
            />
          </div>
        </div>
      </div>

      <CustomTable
        data={filteredData}
        columns={columns}
        selectedColumns={selectedColumns}
        onRowClick={handleRowClick}
        rowsPerPage={10}
        storageKey="jobSqlTableSortState"
      />
    </div>
  );
}

export default JobServiceName;