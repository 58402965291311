//src/components/main_sql/CustomerTable.js
import React from 'react';
import GenericSqlTable from '../GenericSqlTable';

function StaffNameTable({ onSelect }) {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/Staffnamesql/`; // Replace with your actual endpoint
  const columns = [
    { key: 'idstaff', label: 'ID' },
    { key: 'staffname', label: 'Name' },
    // Add more columns as needed
  ];

  return (
    <GenericSqlTable
      apiUrl={apiUrl}
      title="พนักงาน" // Adjust the title as needed
      onSelect={onSelect}
      filterKey="staffcancle" // Replace with the actual field indicating cancellation
      idKey="idstaff"        // Replace with the actual ID field in your data
      nameKey="staffname"    // Replace with the actual name field in your data
      columns={columns}
      searchKey="staffname"  // Replace with the actual field to search by
    />
  );
} 

export default StaffNameTable;