// src/components/jobticket/JobTicketProdVTable.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/apiClient';
import CustomTable from '../../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../redux/slices/columnSlice';
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button components

function JobTicketProdVTable({ jobTicketId, showAll, dataVersion, onDataChange, onAddNew }) {
    const [jobTicketProds, setJobTicketProds] = useState([]);
    const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'jobTicketProd'));
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    // Fetch job ticket products based on jobTicketId and apply the showAll filter
    const fetchJobTicketProds = async (jobTicketId) => {
        try {
            const response = await axiosInstance.get('/Jobticketprodv/', {
                params: {
                    jobticketidto: jobTicketId,
                },
            });

            // If showAll is true, show all products. Otherwise, filter out canceled products.
            const filteredProds = showAll
                ? response.data
                : response.data.filter(prod => !prod.sellprodcancel);

            const processedProds = filteredProds.map((prod) => ({
                ...prod,
                sellprodprice: prod.sellprodprice != null ? prod.sellprodprice : 0,
                sellproddis: prod.sellproddis != null ? prod.sellproddis : 0,
                sellprodqty: prod.sellprodqty != null ? prod.sellprodqty : 0,
            }));

            setJobTicketProds(processedProds);
        } catch (error) {
            console.error('Error fetching job ticket products:', error);
        }
    };

    useEffect(() => {
        if (jobTicketId) {
            fetchJobTicketProds(jobTicketId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobTicketId, showAll, dataVersion]);

    // Calculate the total sum for the 'sum' column
    const calculateTotalSum = () => {
        return jobTicketProds.reduce((acc, job) => {
            const price = Number(job.sellprodprice) || 0;
            const discount = Number(job.sellproddis) || 0;
            const qty = Number(job.sellprodqty) || 0;
            const total = (price - discount) * qty;
            return acc + total;
        }, 0);
    };

    const totalSum = calculateTotalSum();

    const footerData = {
        sellprodqty: {
            content: 'รวม', // 'Total' in Thai
            className: 'footer-total-label',
        },
        sum: {
            content: totalSum.toLocaleString(),
            className: 'footer-sum-price',
        },
    };

    const formatNumberWithCommas = (value) => {
        const number = parseFloat(value.toString().replace(/,/g, ''));
        if (isNaN(number)) return '';
        return number.toLocaleString();
      };

      const [editedValues, setEditedValues] = useState({});

    const handleFormattedInputChange = (rowId, field, value) => {
        const numericValue = parseFloat(value.toString().replace(/,/g, ''));
        setEditedValues((prevState) => ({
          ...prevState,
          [rowId]: {
            ...prevState[rowId],
            [field]: isNaN(numericValue) ? '' : numericValue,
          },
        }));
      };

    const handleBlur = (rowId, field, value) => {
        const numericValue = parseFloat(value.toString().replace(/,/g, ''));
        const updatedValue = isNaN(numericValue) ? '' : numericValue;
      
        setEditedValues((prevState) => ({
          ...prevState,
          [rowId]: {
            ...prevState[rowId],
            [field]: updatedValue,
          },
        }));
      
        // Update backend
        const rowToUpdate = jobTicketProds.find((item) => item.idprodsell === rowId);
        if (rowToUpdate) {
          const updatedRow = {
            ...rowToUpdate,
            [field]: updatedValue,
          };
      
          axiosInstance
            .put(`/Jobticketprodsql/${rowId}/`, updatedRow)
            .then((response) => {
              console.log(`${field} updated successfully:`, response.data);
              // Optionally refresh data or update state
            })
            .catch((error) => {
              console.error(`Error updating ${field}:`, error);
              // Handle error, possibly revert the change
            });
        }
      };

    const openDeleteModal = (row) => {
        setSelectedRow(row);
        setShowDeleteModal(true);
        };

            // Close the delete confirmation modal
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRow(null);
        };

        const handleDelete = async () => {
            if (selectedRow) {
              try {
                // Send a PATCH request to update 'sellprodcancel' to true
                await axiosInstance.patch(`/Jobticketprodsql/${selectedRow.idprodsell}/`, {
                  sellprodcancel: true,
                });
          
                // Refresh the data by fetching the updated job ticket products
                fetchJobTicketProds(jobTicketId);
          
                // Close the delete modal
                setShowDeleteModal(false);
                setSelectedRow(null);
              } catch (error) {
                console.error('Error canceling product:', error);
                alert('Error canceling product.');
              }
            }
          };
        
        
    const columns = [
        {
            key: 'prodname',
            label: 'ชื่อสินค้า',
            sortable: true,
            render: (row) => row.prodname,
        },
        {
            key: 'sellprodqty',
            label: 'จำนวน',
            sortable: true,
            render: (row) => (
              <input
                type="text"
                value={formatNumberWithCommas(
                  editedValues[row.idprodsell]?.sellprodqty ?? row.sellprodqty ?? 0
                )}
                onChange={(e) =>
                  handleFormattedInputChange(row.idprodsell, 'sellprodqty', e.target.value)
                }
                onFocus={(e) => {
                  const rawValue =
                    editedValues[row.idprodsell]?.sellprodqty ?? row.sellprodqty ?? '';
                  e.target.value = rawValue.toString();
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  e.target.value = formatNumberWithCommas(value);
                  handleBlur(row.idprodsell, 'sellprodqty', value);
                }}
                style={{
                  width: `${
                    (
                      (editedValues[row.idprodsell]?.sellprodqty ??
                        row.sellprodqty ??
                        0
                      ).toString().length + 2.5
                    ).toString()
                  }ch`,
                  height: '32px',
                  textAlign: 'right',
                }}
              />
            ),
            className: 'text-center',
          },
        {
            key: 'sellprodprice',
            label: 'ราคา',
            sortable: true,
            render: (row) => (
              <input
                type="text"
                value={formatNumberWithCommas(
                  editedValues[row.idprodsell]?.sellprodprice ??
                    row.sellprodprice ??
                    0
                )}
                onChange={(e) =>
                  handleFormattedInputChange(row.idprodsell, 'sellprodprice', e.target.value)
                }
                onFocus={(e) => {
                  const rawValue =
                    editedValues[row.idprodsell]?.sellprodprice ??
                    row.sellprodprice ??
                    '';
                  e.target.value = rawValue.toString();
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  e.target.value = formatNumberWithCommas(value);
                  handleBlur(row.idprodsell, 'sellprodprice', value);
                }}
                style={{
                  width: `${
                    (
                      (editedValues[row.idprodsell]?.sellprodprice ??
                        row.sellprodprice ??
                        0
                      ).toString().length + 2.5
                    ).toString()
                  }ch`,
                  height: '32px',
                  textAlign: 'right',
                }}
              />
            ),
            className: 'text-end',
          },
        {
            key: 'sellproddis',
            label: 'ลด',
            sortable: true,
            render: (row) => (
              <input
                type="text"
                value={formatNumberWithCommas(
                  editedValues[row.idprodsell]?.sellproddis ??
                    row.sellproddis ??
                    0
                )}
                onChange={(e) =>
                  handleFormattedInputChange(row.idprodsell, 'sellproddis', e.target.value)
                }
                onFocus={(e) => {
                  const rawValue =
                    editedValues[row.idprodsell]?.sellproddis ??
                    row.sellproddis ??
                    '';
                  e.target.value = rawValue.toString();
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  e.target.value = formatNumberWithCommas(value);
                  handleBlur(row.idprodsell, 'sellproddis', value);
                }}
                style={{
                  width: `${
                    (
                      (editedValues[row.idprodsell]?.sellproddis ??
                        row.sellproddis ??
                        0
                      ).toString().length + 2.5
                    ).toString()
                  }ch`,
                  height: '32px',
                  textAlign: 'right',
                }}
              />
            ),
            className: 'text-end',
          },
          {
            key: 'sum',
            label: 'รวม',
            sortable: false,
            render: (row) => {
              const price =
                editedValues[row.idprodsell]?.sellprodprice ??
                row.sellprodprice ??
                0;
              const discount =
                editedValues[row.idprodsell]?.sellproddis ??
                row.sellproddis ??
                0;
              const qty =
                editedValues[row.idprodsell]?.sellprodqty ??
                row.sellprodqty ??
                0;
              const total = (price - discount) * qty;
              return total.toLocaleString();
            },
            className: 'text-end',
          },
        {
            key: 'sellprodcancel',
            label: 'ยกเลิก',
            sortable: true,
            render: (row) => (row.sellprodcancel ? 'ใช่' : 'ไม่ใช่'),
        },
        {
            key: 'delete',
            label: (
              <span
                style={{ cursor: 'pointer', color: 'white' }}
                onClick={onAddNew} // Attach the handleAddData function
              >
                +
              </span>
            ),
            sortable: false,
            render: (row) => (
                <span
                  style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => openDeleteModal(row)}
                >
                  ลบ
                </span>
            ),
            className: 'text-center',
          },
    ];

    useEffect(() => {
        if (selectedColumns.length === 0 && columns.length > 0) {
            const allColumnKeys = columns.map((col) => col.key);
            dispatch(setColumns({ page: 'jobTicketProd', columns: allColumnKeys }));
        }
    }, [dispatch, selectedColumns, columns]);

    const handleColumnsChange = (newSelectedColumns) => {
        dispatch(setColumns({ page: 'jobTicketProd', columns: newSelectedColumns }));
    };

    return (
        <div className="container">
            
                <>
                    <h5>รายการสินค้าในงานนี้</h5>
                    <CustomTable
                        data={jobTicketProds}
                        columns={columns}
                        selectedColumns={selectedColumns}
                        onColumnsChange={handleColumnsChange}
                        rowsPerPage={10}
                        rowIdKey="idprodsell"
                        storageKey="jobTicketProdTableSortState"
                        selectable={false}
                        footerData={footerData}
                        tableClassName="job-ticket-prod-table"
                    />
                    {/* Delete Confirmation Modal */}
                        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
                            <Modal.Header closeButton>
                            <Modal.Title>ยืนยันการลบ</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            {selectedRow && (
                                <p>
                                คุณต้องการลบสินค้า: {selectedRow.catname} {selectedRow.typename}{' '}
                                {selectedRow.prodname} ใช่หรือไม่?
                                </p>
                            )}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={closeDeleteModal}>
                                ยกเลิก
                            </Button>
                            <Button variant="danger" onClick={handleDelete}>
                                ลบ
                            </Button>
                            </Modal.Footer>
                        </Modal>
                </>
        </div>
    );
}

export default JobTicketProdVTable;