// src/components/service_page/ServicePage.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../../utils/apiClient';
import FilterService from './FilterService';  
import ServiceTable from './ServiceTable';    
import ServiceAddForm from './ServiceAddForm';
import ServiceThingAddF from './ServiceThingAddF';
import ServiceJobAddModal from './ServiceJobAddModal'; 
import FilterForm from '../FilterForm'; 
import StockTable from '../StockTable'; 
import { useSelector, useDispatch } from 'react-redux';
import { fetchServiceData } from '../../redux/slices/serviceSlice'; // Adjust the path
import { fetchJobThingData, fetchJobServiceDoneData } from '../../redux/slices/jobSlice';
import JobTicketProdVTable from './JobTicketProdVTable'; // Adjust the path as needed
import { Modal } from 'react-bootstrap';

const ServicePage = () => {
  const [filteredData, setFilteredData] = useState([]); 
  const [resetPage, setResetPage] = useState(false);    
  const [serviceFilters, setServiceFilters] = useState({
    customer: '',
    selectedStatus: 'not_completed',
    showAll: false,
    selectedJobCategory: 'หน้าร้าน',
  });

  const [stockFilters, setStockFilters] = useState({
    name: '',
    categoryname: '',
    typename: '',
    brandname: '',
    modelname: '',
    sizename: '',
    colorname: '',
    barcode: '',
    suppname: '',
  });

  // State variables for managing visibility
  const [showAddForm, setShowAddForm] = useState(false);
  const [showThingtoServAddform, setShowThingtoServAddform] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [expandedServiceId, setExpandedServiceId] = useState(null);
  const [selectedServsellId, setSelectedServsellId] = useState(null); // Add this line
  const [showJobAddform, setShowJobAddform] = useState(false);
  const [expandedChildRowId, setExpandedChildRowId] = useState(null); // Add this line

  const [selectedServiceForEdit, setSelectedServiceForEdit] = useState(null);
  const [selectedThingForEdit, setSelectedThingForEdit] = useState(null);
  const [selectedJobForEdit, setSelectedJobForEdit] = useState(null);

  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.service.status);
  const serviceData = useSelector((state) => state.service.serviceData); // Fetch data from Redux state
  const jobThingData = useSelector((state) => state.job.jobThingData);
  const jobServiceDoneData = useSelector((state) => state.job.jobServiceDoneData);
  const [selectedJobTicketId, setSelectedJobTicketId] = useState(null);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [seeAllStock, setSeeAllStock] = useState(false); // Define seeAllStock
  const [stockData, setStockData] = useState([]); 
  const [selectedProduct, setSelectedProduct] = useState([]); // selected product in modal
  const [dataVersion, setDataVersion] = useState(0);

  useEffect(() => {
    if (fetchStatus === 'idle') {
      dispatch(fetchServiceData());
    }
    dispatch(fetchJobThingData()); // Fetch jobThingData
    dispatch(fetchJobServiceDoneData()); // Fetch jobServiceDoneData
  }, [fetchStatus, dispatch]);

  useEffect(() => {
    dispatch(fetchJobThingData());    // Fetch jobThingData from the server
    dispatch(fetchJobServiceDoneData()); // Fetch jobServiceDoneData from the server
  }, [dispatch]);

  // Extract unique job status options from the data
  const jobStatusOptions = [...new Set(serviceData.map(service => service.jobstatusname))];

  // Extract unique job category group options from the data
  const jobCategoryOptions = [...new Set(serviceData.map(service => service.jobcatgroup))];

  // Update filter state and apply filters
  const handleServiceFilterChange = useCallback(({ customer, selectedStatus, showAll, selectedJobCategory }) => {
    setServiceFilters({ customer, selectedStatus, showAll, selectedJobCategory });
  }, []);

  useEffect(() => {
    const filtered = serviceData.filter((service) => {
      if (
        serviceFilters.customer &&
        (!service.nickname || !service.nickname.toLowerCase().includes(serviceFilters.customer.toLowerCase()))
      ) {
        return false;
      }

      // Handle the "ที่ยังไม่จบงาน" filter
      if (serviceFilters.selectedStatus === 'not_completed' && service.jobstatusname === 'จบงาน') {
        return false;
      }

      // Filter by selected status, if it's not "not_completed"
      if (
        serviceFilters.selectedStatus &&
        serviceFilters.selectedStatus !== 'not_completed' &&
        service.jobstatusname !== serviceFilters.selectedStatus
      ) {
        return false;
      }

      // Filter by selected job category
      if (serviceFilters.selectedJobCategory && service.jobcatgroup !== serviceFilters.selectedJobCategory) {
        return false;
      }

      // Show/Hide canceled jobs based on "All" checkbox
      if (!serviceFilters.showAll && service.jobticketcancle) {
        return false;
      }

      return true;
    });

    setFilteredData(filtered);
    setResetPage((prev) => !prev);
  }, [serviceFilters, serviceData]);

  // Add state for selected columns
  // Initialize selectedColumns with data from localStorage if available
  const [selectedColumns, setSelectedColumns] = useState(() => {
    const savedColumns = localStorage.getItem('selectedColumns');
    return savedColumns ? JSON.parse(savedColumns) : [
      'jobdate',
      'nickname',
      'tel',
      'jobcatgroup',
      'jobstatusname',
      'jobcomment',
      'staffname',
      'jobdatefinishplan',
      'realstart',
      'realfinish',
      'idjobticket',
    ];
  });

  // Save selectedColumns to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  // logic for the ServiceAddForm
  const handleAddService = () => {
    setShowAddForm(true);
  };

  const handleFormClose = () => {
    setShowAddForm(false);
  };

  const handleFormSave = () => {
    dispatch(fetchServiceData()); // Refresh the service data
    setShowAddForm(false);
  };

  // Handle the '+' button click in ServiceTable
  const handleAddThing = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShowThingtoServAddform(true);
    setSelectedThingForEdit(null);
  };

  const handleAddJob = (servsellid, serviceId) => {
    setSelectedServsellId(servsellid); 
    setSelectedServiceId(serviceId); 
    setShowJobAddform(true);
  };

  // Handle saving from ThingtoServAddform
  const handleThingFormSave = (updatedThing) => {
    // Dispatch the action to refetch jobThingData from the server
    dispatch(fetchJobThingData());
    setShowThingtoServAddform(false);
    setExpandedServiceId(selectedServiceId); // Expand the service row
  };


  const handleJobFormSave = async () => {
    // Dispatch actions to fetch updated data
    await dispatch(fetchJobServiceDoneData());
    await dispatch(fetchJobThingData());
    // Close the modal
    setShowJobAddform(false);
    // Keep the rows expanded after refresh
    setExpandedServiceId(selectedServiceId);
    setExpandedChildRowId(selectedServsellId);
  };

  // Handle canceling from ThingtoServAddform
  const handleThingFormCancel = () => {
    setShowThingtoServAddform(false);
  };

  const handleJobFormCancel = () => {
    setShowJobAddform(false);
  };

  const handleEditService = async (service) => {
    try {
      // Fetch customer data 
      const customerResponse = await axiosInstance.get('/Customersql/');
      const customerData = customerResponse.data.find(customer => customer.nickname === service.nickname);
      const customeridto = customerData ? customerData.idcustomer : null;
  
      // Fetch job category data
      const jobcatResponse = await axiosInstance.get('/Jobcatnamesql/');
      const jobcatData = jobcatResponse.data.find(jobcat => jobcat.jobcatgroup === service.jobcatgroup);
      const jobcat = jobcatData ? jobcatData.idjobcat : null;
  
      // Fetch job status data
      const jobstatusResponse = await axiosInstance.get('/Jobstatusnamesql/');
      const jobstatusData = jobstatusResponse.data.find(jobstatus => jobstatus.jobstatusname === service.jobstatusname);
      const jobstatus = jobstatusData ? jobstatusData.idjobstatus : null;
  
      setSelectedServiceForEdit({ ...service, customeridto, jobcat, jobstatus });
      setShowAddForm(true);
    } catch (error) {
      console.error('Error fetching data for editing service:', error);
    }
  };

  const handleEditThing = async (thing) => {
    setSelectedThingForEdit(thing);
    setShowThingtoServAddform(true);
  };

  const handleEditJob = (job) => {
    setSelectedJobForEdit(job);
    setShowJobAddform(true);
  };

  const handleJobAddConfirm = async (formData, servsellid, job) => {
    try {
      const submissionData = {
        ...formData,
        servjobname: formData.idservicename, // Use the integer ID
        servsellid: parseInt(servsellid, 10),
        servjobcancle: false,
        servjobtimestamp: new Date().toISOString(),
        servjobprice: parseInt(formData.servjobprice || '0', 10),
        servjobcomment: formData.servjobcomment || '',
        servjobdisc: parseInt(formData.servjobdisc || '0', 10),
        servjobqty: parseInt(formData.servjobqty || '1', 10),
      };
  
      let response;
      if (job && job.idservjob) {
        // Update existing job
        response = await axiosInstance.put(`/update-service-job/${job.idservjob}/`, submissionData);
      } else {
        // Add new job
        response = await axiosInstance.post('/add-service-job/', submissionData);
      }
  
      console.log('Response from API:', response.data);
      //setShowConfirm(false);
      //setShowSuccess(true);
    } catch (error) {
      console.error('Error saving job:', error);
    }
  };

// Add this state to track the selected service
const [selectedService, setSelectedService] = useState(null);

const handleServiceSelect = (service) => {
  setSelectedService(service);
  setSelectedJobTicketId((prevId) => (prevId === service.idjobticket ? null : service.idjobticket));
  };

  const handleCreateNew = () => {
    setShowAddProductModal(true);
  };

  const handleCloseModal = () => {
    setShowAddProductModal(false);
  };

  const handleClearFilters = () => {
    setStockFilters({
      name: '',
      categoryname: '',
      typename: '',
      brandname: '',
      modelname: '',
      sizename: '',
      colorname: '',
      barcode: '',
      suppname: '',
    });
    fetchStockData(); 
  };

  const handleRefresh = () => {
    fetchStockData(); 
  };

  const handleSeeAllChange = () => {
    setSeeAllStock(!seeAllStock);
  };

  const fetchStockData = useCallback(() => {
    axiosInstance.get(`/stview/`)
      .then(response => {
        setStockData(response.data); 
      })
      .catch(error => {
        console.error('There was an error fetching the stock data!', error);
      });
  }, []);

  useEffect(() => {
    if (showAddProductModal) {
      fetchStockData(); 
    }
  }, [fetchStockData, showAddProductModal]);

  // Update filter state and apply filters for stock data
  const handleStockFilterChange = (newFilters) => {
    setStockFilters(newFilters);
  };

  const filteredStockData = useMemo(() => {
    return stockData.filter(item => {
      const matchesFilters = (
        (item.name || '').toLowerCase().includes(stockFilters.name.toLowerCase()) &&
        (item.categoryname || '').toLowerCase().includes(stockFilters.categoryname.toLowerCase()) &&
        (item.typename || '').toLowerCase().includes(stockFilters.typename.toLowerCase()) &&
        (item.brandname || '').toLowerCase().includes(stockFilters.brandname.toLowerCase()) &&
        (item.modelname || '').toLowerCase().includes(stockFilters.modelname.toLowerCase()) &&
        (item.sizename || '').toLowerCase().includes(stockFilters.sizename.toLowerCase()) &&
        (item.colorname || '').toLowerCase().includes(stockFilters.colorname.toLowerCase()) &&
        (item.barcode || '').toLowerCase().includes(stockFilters.barcode.toLowerCase()) &&
        (item.suppname || '').toLowerCase().includes(stockFilters.suppname.toLowerCase())
      );
      const matchesSeeAll = seeAllStock || (item.st > 0 && !item.prodcancel);
      return matchesFilters && matchesSeeAll;
    });
  }, [stockData, stockFilters, seeAllStock]);

  // Function to handle adding a single selected product
  const handleAddSelectedToServ = async () => {
    if (!selectedProduct || selectedProduct.length === 0) {
      alert("Please select a product to add.");
      return;
    }
  
    // Prepare the array of product data
    const dataToAdd = selectedProduct.map((product) => ({
      sellprodid: product.prodid,
      sellprodqty: 1, // Set default qty to 1
      sellprodprice: product.price, // Set price from StockTable
      jobticketidto: selectedJobTicketId,
      sellproddis: 0, // Set default discount to 0
    }));
  
    console.log("Data to add:", dataToAdd); // Verify the data structure
  
    try {
      // Send a single POST request with the array of products
      await axiosInstance.post('/Jobticketprodsql/bulk_create/', dataToAdd);
      handleCloseModal();
      setDataVersion((prevVersion) => prevVersion + 1); // Refresh data after all requests complete
    } catch (error) {
      console.error('Error adding products to Jobticketprodsql:', error.response ? error.response.data : error);
    }
  };

  const onSendtoSell = async () => {
    if (!selectedService) {
      alert('กรุณาเลือกงานเพื่อออกบิล');
    } else {
      const { customeridto, nickname, jobcomment, idjobticket } = selectedService;

      try {
        // Fetch JobTicketProdV data for the selected service
        const response = await axiosInstance.get('/Jobticketprodv/', {
          params: { 
            jobticketidto: idjobticket,
            sellprodcancel: false,
           },
        });
        const jobTicketProdData = response.data;
        // Filter out items where sellprodcancel is true
        console.log('JobTicketProdV data:', jobTicketProdData);
        if (jobTicketProdData.length > 0) {
          // There is data, add products to Sellprodtoadd
          const requests = jobTicketProdData.map((item) => {
            const dataToAdd = {
              sellprodidtoadd: item.sellprodid,
              sellprodpricetoadd: item.sellprodprice,
              sellprodqtytoadd: item.sellprodqty,
              sellproddistoadd: item.sellproddis,
            };
            return axiosInstance.post('/Sellprodtoadd/', dataToAdd);
          });
          // Wait for all POST requests to complete
          await Promise.all(requests);
        }


      // Fetch Jobthingtodov data (Lv1 Child Rows) for the selected service
      const jobThingResponse = await axiosInstance.get('/Jobthingtodov/', {
        params: {
          serviceid: idjobticket,
          servcancel: false,
        },
      });
      const jobThingData = jobThingResponse.data;

      let serviceselltoaddIdMap = {}; // To map original idservicesell to new idserviceselltoadd

      if (jobThingData.length > 0) {
        // There is data, add services to Serviceselltoaddsql
        const serviceRequests = jobThingData.map((item) => {
          const dataToAdd = {
            servprodcattoadd: item.catid || null,
            servprodbrandtoadd: item.brandid || null,
            servprodmodeltoadd: item.modelid || null,
            servprodsizetoadd: item.idprodsize || null,
            servprodcolortoadd: item.idprodcolor || null,
            servcommenttoadd: '', // Include any relevant comment if available
          };
          return axiosInstance.post('/Serviceselltoaddsql/', dataToAdd);
        });

        // Wait for all service POST requests to complete
        const serviceselltoaddResponses = await Promise.all(serviceRequests);

        // Map the idservicesell returned from Serviceselltoaddsql to the original idservicesell
        serviceselltoaddResponses.forEach((response, index) => {
          const originalIdservicesell = jobThingData[index].idservicesell;
          const newIdserviceselltoadd = response.data.idserviceselltoadd;
          serviceselltoaddIdMap[originalIdservicesell] = newIdserviceselltoadd;
        });
      }

      // Now, fetch Jobservicedonev data (Lv2 Child Rows) for the selected service
      const jobServiceResponse = await axiosInstance.get('/Jobservicedonev/', {
        params: {
          serviceid: idjobticket,
          servjobcancle: false,
        },
      });
      const jobServiceData = jobServiceResponse.data;

      if (jobServiceData.length > 0) {
        // There is data, add jobs to Servjobtoaddsql
        const jobRequests = jobServiceData.map((item) => {
          // Map servsellid to the new servsellidto in Serviceselltoaddsql
          const originalServsellid = item.servsellid;
          const servsellidto = serviceselltoaddIdMap[originalServsellid];

          const dataToAdd = {
            servjobnametoadd: item.servjobname || null,
            servjobcommenttoadd: item.servjobcomment || '',
            servjobstafftoadd: null, // Include staff ID if available
            servjobpricetoadd: item.servjobprice || 0,
            servjobdisctoadd: item.servjobdisc || 0,
            servjobqtytoadd: item.servjobqty || 1,
            servsellidto: servsellidto, // Use the mapped servsellidto
          };
          return axiosInstance.post('/Servjobtoaddsql/', dataToAdd);
        });
        // Wait for all job POST requests to complete
        await Promise.all(jobRequests);
      }

      //store customer name and id to local storage
      localStorage.setItem('customer', nickname);
      localStorage.setItem('customerId', customeridto);
      localStorage.setItem('sellComment', jobcomment);
      window.location.href = '/sellpage'; // Or use navigate('/sellpage');
      } catch (error) {
        console.error('Error processing the sell:', error);
        alert('เกิดข้อผิดพลาดในการออกบิล กรุณาลองใหม่อีกครั้ง');
      }
    }
  };

  return (
    <div className="container">
      {/* Conditionally render components based on state */}
      {!showAddForm && !showThingtoServAddform && !showJobAddform && (
        <>
          <FilterService 
            filters={serviceFilters} // Pass serviceFilters
            onFilter={handleServiceFilterChange} 
            jobStatusOptions={jobStatusOptions}
            jobCategoryOptions={jobCategoryOptions}
            selectedColumns={selectedColumns}
            onColumnsChange={setSelectedColumns}
            onAddService={handleAddService}
            selectedService={selectedService}
            onSendtoSell={onSendtoSell}
          />

          <ServiceTable 
            data={filteredData} 
            jobThingData={jobThingData}
            jobServiceDoneData={jobServiceDoneData}
            resetPage={resetPage} 
            showCancelColumn={serviceFilters.showAll} // Use serviceFilters.showAll
            selectedColumns={selectedColumns}
            onAddThing={handleAddThing} // Pass the handler to ServiceTable
            onAddjob={handleAddJob} // Pass the handler to ServiceTable
            onAddService={handleAddService}
            expandedServiceId={expandedServiceId} // Pass the service ID to expand
            expandedChildRowId={expandedChildRowId} // Add this line
            onEditService={handleEditService}
            onEditThing={handleEditThing}
            onEditJob={handleEditJob}
            fetchServiceData={() => dispatch(fetchServiceData())} // Use the Redux action
            onServiceSelect={handleServiceSelect}
          />
        </>
      )}

      {showAddForm && (
        <ServiceAddForm
          formTitle={selectedServiceForEdit ? 'Edit Service' : 'สร้างใบงานใหม่'}
          onSave={handleFormSave}
          onCancel={handleFormClose}
          service={selectedServiceForEdit}
          customeridto={selectedServiceForEdit?.customeridto}
          jobcat={selectedServiceForEdit?.jobcat}
          jobstatus={selectedServiceForEdit?.jobstatus}
        />
      )}

      {showThingtoServAddform && (
        <ServiceThingAddF
          serviceId={selectedServiceId}
          thing={selectedThingForEdit}
          onSave={handleThingFormSave}
          onCancel={handleThingFormCancel}
          show={showThingtoServAddform}
        />
      )}

      {showJobAddform && (
        <ServiceJobAddModal
          servsellid={selectedServsellId}
          onSave={handleJobFormSave}
          onCancel={handleJobFormCancel}
          show={showJobAddform}
          handleConfirm={handleJobAddConfirm}
          job={selectedJobForEdit}
        />
      )}

      {selectedJobTicketId && (
        <JobTicketProdVTable
          jobTicketId={selectedJobTicketId}
          showAll={serviceFilters.showAll} // Use serviceFilters.showAll
          onAddNew={handleCreateNew}
          dataVersion={dataVersion} // Pass dataVersion to trigger refresh
        />
      )}

      <Modal show={showAddProductModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilterForm
            filters={stockFilters} // Pass stockFilters
            handleFilterChange={handleStockFilterChange}
            handleClearFilters={handleClearFilters}
            seeAll={seeAllStock} // Use seeAllStock
            handleSeeAllChange={handleSeeAllChange}
            handleRefresh={handleRefresh}
          />
          <StockTable 
            data={filteredStockData} 
            seeAll={seeAllStock} // Use seeAllStock
            selectable={true}
            onSelectionChange={setSelectedProduct} // Capture selected products here          
            hideActions={true} // Pass hideActions as true when modal is displayed
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            ปิด  
          </button>
          <button className="btn btn-warning" onClick={handleAddSelectedToServ}>
            เพิ่มสินค้าไปเซอร์วิส
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ServicePage;