// src/redux/slices/jobSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/apiClient';

// Async thunk to fetch jobThingData
export const fetchJobThingData = createAsyncThunk(
  'job/fetchJobThingData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/Jobthingtodov/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);



// Async thunk to fetch jobServiceDoneData
export const fetchJobServiceDoneData = createAsyncThunk(
  'job/fetchJobServiceDoneData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/Jobservicedonev/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

const jobSlice = createSlice({
  name: 'job',
  initialState: {
    jobThingData: [],
    jobServiceDoneData: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobThingData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobThingData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobThingData = action.payload;
      })
      .addCase(fetchJobThingData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchJobServiceDoneData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobServiceDoneData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobServiceDoneData = action.payload;
      })
      .addCase(fetchJobServiceDoneData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default jobSlice.reducer;