import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/apiClient';
import CustomTable from '../../../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../../redux/slices/columnSlice';
import DoWithLineModal from '../../../utils/DoWithLineModal';

function SellServViewTable({ onSellServSelect, sellBillId, showAll, dataVersion }) {
  const [sellServices, setSellServices] = useState([]);
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'sellServ'));
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedSellServ, setSelectedSellServ] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  // Fetch sell services based on sellBillId and apply the showAll filter
  const fetchSellServices = async (sellBillId) => {
    try {
      const response = await axiosInstance.get('/Sellservv/', {
        params: {
          servtosellbillid: sellBillId,
        },
      });

      // If showAll is true, show all services. Otherwise, filter out canceled services.
      const filteredServices = showAll 
        ? response.data 
        : response.data.filter(serv => !serv.servcancel);

    // Get all service IDs
    const serviceIds = filteredServices.map((serv) => serv.idservicesell);

    // Fetch sell jobs for all services
    const jobsResponse = await axiosInstance.get('/Selljobv/', {
      params: {
        servsellid__in: serviceIds.join(','), // Assuming your backend supports this
      },
    });
      // Map jobs to their corresponding services
      const jobsByService = {};
      jobsResponse.data.forEach((job) => {
        if (!job.servjobcancle) {
          if (!jobsByService[job.servsellid]) {
            jobsByService[job.servsellid] = [];
          }
          jobsByService[job.servsellid].push(job);
        }
      });

      // Calculate sumJobPrice for each service
      const servicesWithSum = filteredServices.map((service) => {
        const jobs = jobsByService[service.idservicesell] || [];
        const sumJobPrice = jobs.reduce((sum, job) => {
          const price = Number(job.servjobprice) || 0;
          const discount = Number(job.servjobdisc) || 0;
          const qty = Number(job.servjobqty) || 0;
          const total = (price - discount) * qty;
          return sum + total;
        }, 0);

        return {
          ...service,
          sumJobPrice,
        };
      });

      setSellServices(servicesWithSum);
    } catch (error) {
      console.error('Error fetching sell services:', error);
    }
  };

  useEffect(() => {
    if (sellBillId) {
      fetchSellServices(sellBillId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellBillId, showAll, dataVersion]);

  const calculateTotalSum = () => {
    return sellServices.reduce((acc, job) => {
      const price = Number(job.sumJobPrice) || 0;
      return acc + price;
    }, 0);
  };

  const totalSum = calculateTotalSum();

  // Prepare footer data
  const footerData = {
    servcomment: {
      content: 'รวม', // 'Total' in Thai
      className: 'footer-total-label', // Custom class name
    },
    sumJobPrice: {
      content: totalSum.toLocaleString(),
      className: 'footer-sum-price', // Custom class name
    },
  };

  // Define the columns for the CustomTable
  const columns = [
    {
      key: 'categoryname',
      label: 'กลุ่ม',
      sortable: true,
      render: (row) => row.categoryname,
    },
    {
      key: 'brandname',
      label: 'ยี่ห้อ',
      sortable: true,
      render: (row) => row.brandname,
    },
    {
      key: 'modelname',
      label: 'รุ่น',
      sortable: true,
      render: (row) => row.modelname,
    },
    {
      key: 'sizename',
      label: 'ไซส์',
      sortable: true,
      render: (row) => row.sizename,
    },
    {
      key: 'colorname',
      label: 'สี',
      sortable: true,
      render: (row) => row.colorname,
    },
    {
      key: 'servfinishdateplan',
      label: 'วันที่เสร็จสิ้นแผน',
      sortable: true,
      render: (row) => row.servfinishdateplan || '-',
    },
    {
      key: 'servcomment',
      label: 'หมายเหตุ',
      sortable: true,
      render: (row) => row.servcomment || '-',
    },
    {
      key: 'sumJobPrice',
      label: 'ค่าเซอร์วิซ',
      sortable: false,
      render: (row) =>
        row.sumJobPrice != null ? row.sumJobPrice.toLocaleString() : '0',
      className: 'text-end',
    },
    {
      key: 'servcancel',
      label: 'ยกเลิก',
      sortable: true,
      render: (row) => (row.servcancel ? 'ใช่' : 'ไม่ใช่'),
    },
  ];

  // Initialize selected columns in Redux store if not already set
  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      // Set selected columns to show all columns by default if there are no selected columns in Redux
      const allColumnKeys = columns.map((col) => col.key);
      dispatch(setColumns({ page: 'sellServ', columns: allColumnKeys }));
    }
  }, [dispatch, selectedColumns, columns]);

  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'sellServ', columns: newSelectedColumns }));
  };

  // Handle Edit click
  const handleEditClick = (sellServ) => {
    setSelectedSellServ(sellServ);
    setShowModal(true);
    setIsDeleteConfirmation(false); // Reset delete confirmation state
  };

  // Handle Delete action
  const onDelete = () => {
    setIsDeleteConfirmation(true);
  };

    // Handle row click
    const handleRowClick = (rowData) => {
      if (onSellServSelect) {
        onSellServSelect(rowData.idservicesell);
      }
    };

  // Confirm Delete action
  const onConfirmDelete = async () => {
    if (selectedSellServ) {
      try {
        // Update servcancel to true for the selected service
        await axiosInstance.patch(`/Sellservsql/${selectedSellServ.idservicesell}/`, {
          servcancel: true,
        });

        // Refresh the sell services data
        fetchSellServices(sellBillId);

        setShowModal(false);
        setIsDeleteConfirmation(false);
        alert('Sell service canceled successfully.');
      } catch (error) {
        console.error('Error canceling sell service:', error);
        alert('Error canceling sell service.');
      }
    }
  };

  const onCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  return (
    <div className="container">
        <h5>ของมาเซอร์วิซ</h5>
          <CustomTable
            data={sellServices}
            columns={columns}
            selectedColumns={selectedColumns}
            onColumnsChange={handleColumnsChange}
            rowsPerPage={10}
            rowIdKey="idservicesell" // Use the correct key for service ID
            storageKey="sellServTableSortState"
            showEditColumn={true} // Enable the Edit column
            onEdit={handleEditClick} // Pass the edit handler
            onRowClick={handleRowClick} // Pass the row click handler
            selectable={false}
            footerData={footerData} // Pass the footer data
            tableClassName="sell-job-table" // Add this line
          />
          {/* DoWithLineModal for handling edit/delete actions */}
          <DoWithLineModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setIsDeleteConfirmation(false);
            }}
            itemName={`${selectedSellServ ? selectedSellServ.categoryname : ''} ${selectedSellServ ? selectedSellServ.brandname : ''} ${selectedSellServ ? selectedSellServ.modelname : ''} ${selectedSellServ ? selectedSellServ.sizename : ''} ${selectedSellServ ? selectedSellServ.colorname : ''}`}
            onEdit={() => {
              // Implement edit functionality if needed
            }}
            onDelete={onDelete}
            onConfirmDelete={onConfirmDelete}
            onCancelDeleteConfirmation={onCancelDeleteConfirmation}
            isDeleteConfirmation={isDeleteConfirmation}
            showCreateNew={false}
          />
    </div>
  );
}

export default SellServViewTable;