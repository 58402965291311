// src/components/StockTable.js

import React, { useState, useEffect, useMemo } from 'react';
import CustomTable from '../utils/CustomTable';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../redux/slices/columnSlice';

function StockTable({
  data,
  onEdit,
  seeAll,
  hideActions,
  onAddNew,
  selectable = false,
  onSelectionChange,
}) {
  const dispatch = useDispatch();

  // Get selected columns from Redux store
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, 'stock'));

  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleRowClick = (product) => {
    setSelectedProductId(product.prodid);
  };

  const handleEditClick = (product) => {
    if (onEdit) {
      onEdit(product);
    }
  };

  // Memoize the columns array
  const columns = useMemo(() => {
    const baseColumns = [
      { key: 'categoryname', label: 'ประเภท', render: (item) => item.categoryname },
      { key: 'typename', label: 'กลุ่ม', render: (item) => item.typename },
      { key: 'brandname', label: 'ยี่ห้อ', render: (item) => item.brandname },
      { key: 'modelname', label: 'รุ่น', render: (item) => item.modelname },
      { key: 'sizename', label: 'ไซส์', render: (item) => item.sizename },
      { key: 'colorname', label: 'สี', render: (item) => item.colorname },
      {
        key: 'st',
        label: 'ชิ้น',
        className: 'text-center',
        render: (item) => item.st,
      },
      {
        key: 'prodprice1',
        label: 'ราคาตั้ง',
        className: 'text-center',
        render: (item) => item.prodprice1 != null ? item.prodprice1.toLocaleString() : '', // Show 0 if price is 0
      },
      {
        key: 'price',
        label: 'ราคา',
        className: 'text-center',
        render: (item) => item.price != null ? item.price.toLocaleString() : '', // Show 0 if price is 0
      },
      { key: 'suppname', label: 'ผู้นำเข้า', render: (item) => item.suppname },
      { key: 'barcode', label: 'Barcode', render: (item) => item.barcode },
      { key: 'prodid', label: 'ID', render: (item) => item.prodid },
      {
        key: 'prodcost',
        label: 'ทุน',
        className: 'text-center',
        render: (item) => item.prodcost != null ? item.prodcost.toLocaleString() : '', // Show 0 if cost is 0
      },
    ];

    if (seeAll) {
      baseColumns.push({
        key: 'prodcancel',
        label: 'Cancel',
        render: (item) => (item.prodcancel ? 'Yes' : '-'),
      });
    }

    return baseColumns;
  }, [seeAll]);

  useEffect(() => {
    if (selectedColumns.length === 0 && columns.length > 0) {
      dispatch(setColumns({ page: 'stock', columns: columns.map((col) => col.key) }));
    }
  }, [dispatch, selectedColumns, columns]);

  const onColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: 'stock', columns: newSelectedColumns }));
  };

  const rowClassName = (item) => {
    return selectedProductId === item.prodid ? 'table-active' : '';
  };

  return (
    <div className="table-container">
      <CustomTable
        data={data}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={onColumnsChange}
        onRowClick={handleRowClick}
        rowClassName={rowClassName}
        rowsPerPage={10}
        storageKey="stockTableSortState"
        page="stock"
        hideActions={hideActions}
        showAddButton={!!onAddNew}
        onAddNew={onAddNew}
        showEditColumn={!!onEdit} // Show edit column if onEdit is provided
        onEdit={handleEditClick}
        selectable={selectable}
        onSelectionChange={onSelectionChange}
        rowIdKey="prodid"
      />
    </div>
  );
}

export default StockTable;