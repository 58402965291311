// src/components/NavBar.js

import Container from 'react-bootstrap/Container';
import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './CustomNavbar.css';

const NavBar = ({ username, handleLogout, handleSidebarToggle }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navigate = useNavigate();

  const handleSidebar = () => {
    handleSidebarToggle(); // Open sidebar
    setIsNavCollapsed(true); // Collapse navbar after sidebar opens
  };

    // Function to get the active nav label based on the current path
    const getActiveNavLabel = () => {
      switch (location.pathname) {
        case '/':
          return 'สินค้า'; // Stock
        case '/service':
          return 'บริการ'; // Service
        case '/sellpage':
          return 'ขาย'; // Sell
        case '/SellBillViewPage':
          return 'ดูบิลขาย'; // Sell Bill View
        case '/adjpage':
          return 'ปรับสต็อก'; // Adjust Stock
        default:
          return '';
      }
    };

      // Log the isNavCollapsed state whenever it changes
  useEffect(() => {
    console.log('Navbar collapsed state changed:', isNavCollapsed);
  }, [isNavCollapsed]);


  const location = useLocation();
  return (
    <Navbar bg="light" expand="md" className="mb-1 custom-navbar-padding" expanded={!isNavCollapsed}>
      <Container>
        <Navbar.Brand className="ms-3">
          Hero Bike
          <span className="active-nav-label ms-3">{getActiveNavLabel()}</span>
          </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleSidebar} // Only use this to toggle sidebar and collapse navbar
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => { setIsNavCollapsed(true); navigate('/'); }}
              className={location.pathname === '/' ? 'nav-link-active' : ''}
            >
              สินค้า
            </Nav.Link>
            <Nav.Link
              onClick={() => { setIsNavCollapsed(true); navigate('/service'); }}
              className={location.pathname === '/service' ? 'nav-link-active' : ''}
            >
              บริการ
            </Nav.Link>
            <Nav.Link
              onClick={() => { setIsNavCollapsed(true); navigate('/sellpage'); }}
              className={location.pathname === '/sellpage' ? 'nav-link-active' : ''}
            >
              ขาย
            </Nav.Link>
            <Nav.Link
              onClick={() => { setIsNavCollapsed(true); navigate('/SellBillViewPage'); }}
              className={location.pathname === '/SellBillViewPage' ? 'nav-link-active' : ''}
            >
              ดูบิลขาย
            </Nav.Link>
            <Nav.Link
              onClick={() => { setIsNavCollapsed(true); navigate('/adjpage'); }}
              className={location.pathname === '/adjpage' ? 'nav-link-active' : ''}
            >
              ปรับสต็อก
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto me-3">
            {username && (
              <>
                <Nav.Item className="me-3">
                  <span
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => { setIsNavCollapsed(true); navigate('/change-password'); }}
                  >
                    {username}
                  </span>
                </Nav.Item>
                <span
                  style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleLogout}
                >
                  ออกจากระบบ
                </span>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;