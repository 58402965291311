// src/components/product_sql/SqlTable.js

import React, { useState, useEffect, useRef } from 'react';
import CustomTable from '../../utils/CustomTable';
import axiosInstance from '../../utils/apiClient';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { setColumns, selectSelectedColumns } from '../../redux/slices/columnSlice';
import DoWithLineModal from '../../utils/DoWithLineModal';
import AddOrEditModal from '../../utils/AddOrEditModal'; // Adjust the import path as needed

function SqlTable({
  onSelect,
  onClose,
  endpoint,
  columns,
  itemName,
  dataKey,
  idKey,
  pageName,
  storageKey,
  cancelKey = 'cancel',
  fields = [],
}) {
  const dispatch = useDispatch();
  const selectedColumns = useSelector((state) => selectSelectedColumns(state, pageName));
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const searchInputRef = useRef(null);
  const [loading, setLoading] = useState(true);

  // State variables for creating an item
  const [newItemData, setNewItemData] = useState({});
  const [creatingItem, setCreatingItem] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  // State variables for editing an item
  const [editItemData, setEditItemData] = useState({});
  const [editingItem, setEditingItem] = useState(false);
  const [editError, setEditError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  // State variables for action modal
  const [showActionModal, setShowActionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  // Fetch items on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(endpoint);
        const activeItems = response.data.filter((item) => !item[cancelKey]);
        setData(activeItems);
      } catch (error) {
        console.error(`Error fetching ${itemName.toLowerCase()} data:`, error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [endpoint, itemName, cancelKey]);

  // Set default columns if none are selected
  useEffect(() => {
    if (selectedColumns.length === 0) {
      dispatch(setColumns({ page: pageName, columns: columns.map((col) => col.key) }));
    }
  }, [dispatch, selectedColumns, columns, pageName]);

  // Focus on search input when data is loaded
  useEffect(() => {
    if (!loading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  // Handle filter change
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Filter data based on search input
  const filteredData = data.filter((item) =>
    fields.some((field) =>
      item[field.key]?.toString().toLowerCase().includes(filter.toLowerCase())
    )
  );

  // Handle row click (selection)
  const handleRowClick = (item) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  // Handle columns change
  const handleColumnsChange = (newSelectedColumns) => {
    dispatch(setColumns({ page: pageName, columns: newSelectedColumns }));
  };

  // Open modal to create new item
  const handleOpenModal = () => {
    const initialData = {};
    fields.forEach((field) => {
      initialData[field.key] = '';
    });
    setNewItemData(initialData);
    setCreateError(null);
    setShowCreateModal(true);
  };

  // Close create item modal
  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  // Create a new item
  const handleCreateItem = async () => {
    // Validate required fields
    for (let field of fields) {
      if (field.required && !newItemData[field.key].trim()) {
        setCreateError(`${field.label} cannot be empty.`);
        return;
      }
    }

    setCreatingItem(true);
    setCreateError(null);
    console.log('Data being sent:', newItemData); // Log the payload

    try {
      const dataToSend = { ...newItemData, [cancelKey]: false };
      const response = await axiosInstance.post(endpoint, dataToSend);

      setData((prevData) => [...prevData, response.data]);
      setShowCreateModal(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setCreateError(`${itemName} already exists or invalid data.`);
      } else {
        console.error(`Error creating ${itemName.toLowerCase()}:`, error);
        setCreateError(`Failed to create ${itemName.toLowerCase()}. Please try again.`);
      }
    } finally {
      setCreatingItem(false);
    }
  };

  // Handle edit click in action column
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setShowActionModal(true);
    setIsDeleteConfirmation(false);
  };

  // Open edit item modal
  const handleEditItem = () => {
    setShowActionModal(false);
    const initialData = {};
    fields.forEach((field) => {
      initialData[field.key] = selectedItem[field.key] || '';
    });
    setEditItemData(initialData);
    setEditError(null);
    setShowEditModal(true);
  };

  // Update the item
  const handleUpdateItem = async () => {
    // Validate required fields
    for (let field of fields) {
      if (field.required && !editItemData[field.key].trim()) {
        setEditError(`${field.label} cannot be empty.`);
        return;
      }
    }

    setEditingItem(true);
    setEditError(null);

    try {
      const dataToSend = { ...editItemData, [cancelKey]: selectedItem[cancelKey] };
      const response = await axiosInstance.put(`${endpoint}${selectedItem[idKey]}/`, dataToSend);

      // Update the data in the table
      setData((prevData) =>
        prevData.map((item) => (item[idKey] === selectedItem[idKey] ? response.data : item))
      );
      setShowEditModal(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setEditError(`${itemName} already exists or invalid data.`);
      } else {
        console.error(`Error updating ${itemName.toLowerCase()}:`, error);
        setEditError(`Failed to update ${itemName.toLowerCase()}. Please try again.`);
      }
    } finally {
      setEditingItem(false);
    }
  };

  // Close action modal
  const handleCloseActionModal = () => {
    setShowActionModal(false);
    setIsDeleteConfirmation(false);
  };

  // Initiate delete confirmation
  const handleDeleteItem = () => {
    setIsDeleteConfirmation(true);
  };

  // Confirm deletion of item
  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.patch(`${endpoint}${selectedItem[idKey]}/`, { [cancelKey]: true });
      setData((prevData) => prevData.filter((item) => item[idKey] !== selectedItem[idKey]));
      setShowActionModal(false);
      setIsDeleteConfirmation(false);
    } catch (error) {
      console.error(`Error deleting ${itemName.toLowerCase()}:`, error);
    }
  };

  // Cancel delete confirmation
  const handleCancelDeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-3">
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center">
        <h2>{itemName}</h2>
        <span
          className="text-danger"
          style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
          onClick={onClose}
          aria-label="Close"
          role="button"
        >
          ×
        </span>
      </div>

      {/* Search Input */}
      <div className="mb-3">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className="form-control"
              placeholder={`ค้นหา ${itemName}`}
              value={filter}
              onChange={handleFilterChange}
              ref={searchInputRef}
            />
          </div>
        </div>
      </div>

      {/* Custom Table */}
      <CustomTable
        data={filteredData}
        columns={columns}
        selectedColumns={selectedColumns}
        onColumnsChange={handleColumnsChange}
        onRowClick={handleRowClick}
        rowsPerPage={10}
        storageKey={storageKey}
        page={pageName}
        showAddButton={true} // Show the '+' button
        onAddNew={handleOpenModal} // Function to call when '+' is clicked
        showEditColumn={true} // Show the 'Edit' column
        onEdit={handleEditClick} // Function to call when 'Edit' is clicked
      />

      {/* Modal for creating a new item */}
      <AddOrEditModal
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        fields={fields}
        itemName={itemName}
        itemData={newItemData}
        setItemData={setNewItemData}
        onSave={handleCreateItem}
        saving={creatingItem}
        error={createError}
        mode="create"
      />

      {/* Modal for editing the item */}
      <AddOrEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        fields={fields}
        itemName={itemName}
        itemData={editItemData}
        setItemData={setEditItemData}
        onSave={handleUpdateItem}
        saving={editingItem}
        error={editError}
        mode="edit"
      />

      {/* Modal for edit/delete action */}
      <DoWithLineModal
        show={showActionModal}
        onHide={handleCloseActionModal}
        isDeleteConfirmation={isDeleteConfirmation}
        itemName={selectedItem ? selectedItem[dataKey] : ''}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        onConfirmDelete={handleConfirmDelete}
        onCancelDeleteConfirmation={handleCancelDeleteConfirmation}
        showCreateNew={false}
      />
    </div>
  );
}

export default SqlTable;