import React from 'react';

function TableButton({ label, color, onClick }) {
  return (
    <button className={`btn btn-${color}`} onClick={onClick}>
      {label}
    </button>
  );
}

export default TableButton;